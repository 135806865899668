import React, { useEffect, useState } from "react";
import { Row, TimePicker, Input, Checkbox, Button, Switch } from "antd";
import "../pages/styles/Asistentes.scss";
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AsistentesCard from "../componentes/Asistentes/AsistentesCard";
import DrowArriba from "../Img/Iconos/FlechaArriba.svg";
import DrowAbajo from "../Img/Iconos/FlechaAbajo.svg";
import Info from "../componentes/GeneralComponents/General-Json";
import Modal from "../componentes/GeneralComponents/ModalDetalle";
import ModalDAE from "../componentes/GeneralComponents/ModalDetalle";
import { UseModal } from "../hooks/UseModal";
import AddAsistent from "../componentes/Asistentes/ListaDeAsistentes";
import {
  getAsistentes_request,
  detailAsistentes_request,
  getAsistentesToConfig_request,
  editAssitant_request,
  deleteAsistentes_request,
  addAssitant_request,
} from "../services/asistentes_services";
// import moment from 'moment';
import dayjs from "dayjs";
import Iconos from "../componentes/IconosDeSitio";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { CheckBox, Edit } from "@mui/icons-material";
import Campos from "../componentes/GeneralComponents/campos";

import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Asistentes() {
  //use Modal add
  const [isOpenModal, OpenModal, CloseModal] = UseModal(false);

  // Modal detalle, Agregar y editar
  const [isopenModalDAE, openModalDAE, CloseModalDAE] = UseModal(false);
  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // Cambiar modal a detalle/Agregar/Editar
  const [AccionesAsist, setAccionesAsist] = useState(0);
  // Cambiar tipo de Horario
  const [HorarioTipo, setHorarioTipo] = useState(0);

  // Activacion de Acordiones
  const [Horario, setHorario] = useState(0);
  const [Permisos, setPermisos] = useState(0);
  const [ModuloCitas, setModuloCitas] = useState(1);
  const [ModuloContactos, setModuloContactos] = useState(1);
  const [ModuloAnuncios, setModuloAnuncios] = useState(1);
  const [ModuloChat, setModuloChat] = useState(1);
  const [ModuloNotificaciones, setModuloNotificaciones] = useState(1);
  const [ModuloConsultas, setModuloConsultas] = useState(1);
  const [ModuloResetas, setModuloResetas] = useState(1);
  const [ModuloEstudios, setModuloEstudios] = useState(1);
  const [ModuloExpediente, setModuloExpediente] = useState(1);
  const [ModuloPV, setModuloPV] = useState(1);
  const [ModuloIngresos, setModuloIngresos] = useState(1);
  const [ModuloVinculacion, setModuloVinculacion] = useState(1);

  const [ActiveMoadalAsist, setActiveMoadalAsist] = useState(3);

  const [AssistantList, setAssistantList] = useState([]);
  const [AssistantListFilter, setAssistantListFilter] = useState([]);

  const [AssistantListToAdd, setAssistantListToAdd] = useState([]);
  const [AssistantListToAddFilter, setAssistantListToAddFilter] = useState([]);

  const [IdAssistant, setIdAssistant] = useState("");
  const [DetailAssistant, setDetailAssistant] = useState("");
  const [EstadoAssistant, setEstadoAssistant] = useState(false);

  const [Lunes, setLunes] = useState(false);
  const [HorarioInicioLunes, setHorarioInicioLunes] = useState("");
  const [HorarioFinLunes, setHorarioFinLunes] = useState("");
  const [Martes, setMartes] = useState(false);
  const [HorarioInicioMartes, setHorarioInicioMartes] = useState("");
  const [HorarioFinMartes, setHorarioFinMartes] = useState("");
  const [Miercoles, setMiercoles] = useState(false);
  const [HorarioInicioMiercoles, setHorarioInicioMiercoles] = useState("");
  const [HorarioFinMiercoles, setHorarioFinMiercoles] = useState("");
  const [Jueves, setJueves] = useState(false);
  const [HorarioInicioJueves, setHorarioInicioJueves] = useState("");
  const [HorarioFinJueves, setHorarioFinJueves] = useState("");
  const [Viernes, setViernes] = useState(false);
  const [HorarioInicioViernes, setHorarioInicioViernes] = useState("");
  const [HorarioFinViernes, setHorarioFinViernes] = useState("");
  const [Sabado, setSabado] = useState(false);
  const [HorarioInicioSabado, setHorarioInicioSabado] = useState("");
  const [HorarioFinSabado, setHorarioFinSabado] = useState("");
  const [Domingo, setDomingo] = useState(false);
  const [HorarioInicioDomingo, setHorarioInicioDomingo] = useState("");
  const [HorarioFinDomingo, setHorarioFinDomingo] = useState("");

  const [HorarioInicioSemana, setHorarioInicioSemana] = useState("");
  const [HorarioFinSemana, setHorarioFinSemana] = useState("");

  // CITAS
  const [Citas, setCitas] = useState(true);
  const [CitasModificar, setCitasModificar] = useState(true);
  const [CitasVisualizar, setCitasVisualizar] = useState(false);

  // CONTACTOS
  const [Contactos, setContactos] = useState(true);

  // ANUNCIOS
  const [Anuncios, setAnuncios] = useState(true);

  // CHAT
  const [Chat, setChat] = useState(true);

  // NOTIFICACIONES
  const [Notificaciones, setNotificaciones] = useState(true);

  // CONSULTAS
  const [Consultas, setConsultas] = useState(true);

  // RECETAS
  const [Recetas, setRecetas] = useState(true);

  // ESTUDIOS
  const [Estudios, setEstudios] = useState(true);

  // HISTORIAL
  const [Historial, setHistorial] = useState(true);

  // PUNTO DE VENTA
  const [PuntoVenta, setPuntoVenta] = useState(true);

  // INGRESOS Y EGRESOS
  const [Ingresos, setIngresos] = useState(true);

  // VINCULACION
  const [Vinculacion, setVinculacion] = useState(true);

  const [InputsEditable, setInputsEditable] = useState(true);

  const [Action, setAction] = useState(0);
  const [InfoToAddAssistant, setInfoToAddAssistant] = useState({
    nombre: "",
    imagen: "",
  });

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  async function getAsistentes() {
    setLoader(true);
    let response = await getAsistentes_request();
    if (response.ok) {
      // console.log(response.data);
      setAssistantList(response.data);
      setAssistantListFilter(response.data);
    } else {
      setAssistantList([]);
      setAssistantListFilter([]);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function detailAssistant(idAssistant) {
    setLoaderModal(true);
    let response = await detailAsistentes_request(idAssistant);
    if (response.ok) {
      console.log(response.data);
      let tipoH = !!response.data.Horario.TipoHorario ? response.data.Horario.TipoHorario : 0;
      setDetailAssistant(response.data);
      setEstadoAssistant(response.data.Estado === "Activo" ? true : false);
      setHorarioTipo(tipoH);

      if (tipoH === 1) {
        if (response.data.Horario.MO === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioLunes);
          setHorarioFinSemana(response.data.Horario.HoraFinLunes);
        } else if (response.data.Horario.TU === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioMartes);
          setHorarioFinSemana(response.data.Horario.HoraFinMartes);
        } else if (response.data.Horario.WE === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioMiercoles);
          setHorarioFinSemana(response.data.Horario.HoraFinMiercoles);
        } else if (response.data.Horario.TH === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioJueves);
          setHorarioFinSemana(response.data.Horario.HoraFinJueves);
        } else if (response.data.Horario.FR === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioViernes);
          setHorarioFinSemana(response.data.Horario.HoraFinViernes);
        } else if (response.data.Horario.SA === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioSabado);
          setHorarioFinSemana(response.data.Horario.HoraFinSabado);
        } else if (response.data.Horario.SU === 1) {
          setHorarioInicioSemana(response.data.Horario.HoraInicioDomingo);
          setHorarioFinSemana(response.data.Horario.HoraFinDomingo);
        }

        setLunes(response.data.Horario.MO === 1 ? true : false);
        setMartes(response.data.Horario.TU === 1 ? true : false);
        setMiercoles(response.data.Horario.WE === 1 ? true : false);
        setJueves(response.data.Horario.TH === 1 ? true : false);
        setViernes(response.data.Horario.FR === 1 ? true : false);
        setSabado(response.data.Horario.SA === 1 ? true : false);
        setDomingo(response.data.Horario.SU === 1 ? true : false);
      } else {
        setLunes(response.data.Horario.MO === 1 ? true : false);
        setHorarioInicioLunes(response.data.Horario.HoraInicioLunes);
        setHorarioFinLunes(response.data.Horario.HoraFinLunes);
        setMartes(response.data.Horario.TU === 1 ? true : false);
        setHorarioInicioMartes(response.data.Horario.HoraInicioMartes);
        setHorarioFinMartes(response.data.Horario.HoraFinMartes);
        setMiercoles(response.data.Horario.WE === 1 ? true : false);
        setHorarioInicioMiercoles(response.data.Horario.HoraInicioMiercoles);
        setHorarioFinMiercoles(response.data.Horario.HoraFinMiercoles);
        setJueves(response.data.Horario.TH === 1 ? true : false);
        setHorarioInicioJueves(response.data.Horario.HoraInicioJueves);
        setHorarioFinJueves(response.data.Horario.HoraFinJueves);
        setViernes(response.data.Horario.FR === 1 ? true : false);
        setHorarioInicioViernes(response.data.Horario.HoraInicioViernes);
        setHorarioFinViernes(response.data.Horario.HoraFinViernes);
        setSabado(response.data.Horario.SA === 1 ? true : false);
        setHorarioInicioSabado(response.data.Horario.HoraInicioSabado);
        setHorarioFinSabado(response.data.Horario.HoraFinSabado);
        setDomingo(response.data.Horario.SU === 1 ? true : false);
        setHorarioInicioDomingo(response.data.Horario.HoraInicioDomingo);
        setHorarioFinDomingo(response.data.Horario.HoraFinDomingo);
      }

      setCitas(response.data.Citas.Disabled === 0 ? false : true);
      // setCitasModificar(response.data.Citas.Editor === 1 ? true : false);
      // setCitasVisualizar(response.data.Citas.Lector === 1 ? true : false);

      setContactos(response.data.Contactos.Disabled === 0 ? false : true);
      // setContactosModificar(
      //   response.data.Contactos.Editor === 1 ? true : false
      // );
      // setContactosVisualizar(
      //   response.data.Contactos.Lector === 1 ? true : false
      // );

      setAnuncios(response.data.Anuncios.Disabled === 0 ? false : true);
      // setAnunciosAlta(response.data.Anuncios.Editor === 1 ? true : false);
      // setAnunciosVisualizar(response.data.Anuncios.Lector === 1 ? true : false);

      setChat(response.data.Chat.Disabled === 0 ? false : true);
      // setChatEditar(response.data.Chat.Disabled === 1 ? true : false);
      // setChatLectura(response.data.Chat.Lector === 1 ? true : false);

      setNotificaciones(response.data.Notificaciones.Disabled === 0 ? false : true);
      // setNotificacionesEditar(response.data.Notificaciones.Editor === 1 ? true : false);
      // setNotificacionesEditar(
      //   response.data.Notificaciones.Lector === 1 ? true : false
      // );
      // setNotificacionesVisualizar(
      //   response.data.Notificaciones.Lector === 1 ? true : false
      // );

      setConsultas(response.data.Consultas.Disabled === 0 ? false : true);
      // setConsultasEditar(response.data.Consultas.Editor === 1 ? true : false);
      // setConsultasLectura(response.data.Consultas.Lector === 1 ? true : false);

      setRecetas(response.data.Recetas.Disabled === 0 ? false : true);
      // setRecetasEditar(response.data.Recetas.Editor === 1 ? true : false);
      // setRecetasLectura(response.data.Recetas.Lector === 1 ? true : false);

      setEstudios(response.data.Estudios.Disabled === 0 ? false : true);
      // setEstudiosEditar(response.data.Estudios.Editor === 1 ? true : false);
      // setEstudiosLectura(response.data.Estudios.Lector === 1 ? true : false);

      setHistorial(response.data.Historial.Disabled === 0 ? false : true);
      // setHistorialEditar(response.data.Historial.Editor === 1 ? true : false);
      // setHistorialLectura(response.data.Historial.Lector === 1 ? true : false);

      setPuntoVenta(response.data.PuntoVenta.Disabled === 0 ? false : true);
      // setPuntoVentaAlta(response.data.PuntoVenta.Editor === 1 ? true : false);
      // setPuntoVentaVisualizar(
      //   response.data.PuntoVenta.Lector === 1 ? true : false
      // );

      setIngresos(response.data.Ingresos.Disabled === 0 ? false : true);
      // setIngresosEditar(response.data.Ingresos.Editor === 1 ? true : false);
      // setIngresosLectura(response.data.Ingresos.Lector === 1 ? true : false);

      setVinculacion(response.data.Vinculacion.Disabled === 0 ? false : true);
      // setVinculacionEditar(
      //   response.data.Vinculacion.Editor === 1 ? true : false
      // );
      // setVinculacionLectura(
      //   response.data.Vinculacion.Lector === 1 ? true : false
      // );
    } else {
    }
    setLoaderModal(false);
  }

  async function getAsistentesToConfig() {
    let response = await getAsistentesToConfig_request();
    if (response.ok) {
      // console.log(response.data);
      setAssistantListToAdd(response.data);
      setAssistantListToAddFilter(response.data);
    } else {
    }
  }

  async function editAssistant() {
    if (
      Lunes ||
      Martes ||
      Miercoles ||
      Jueves ||
      Viernes ||
      Sabado ||
      Domingo
    ) {
      if (Citas ||
        Contactos ||
        Anuncios ||
        Chat ||
        Notificaciones ||
        PuntoVenta ||
        Ingresos ||
        Vinculacion) {
        let data = new FormData();
        data.append("id", DetailAssistant.IdAsistente);
        data.append("Estado", EstadoAssistant ? 1 : 0);
        data.append("tipoHorario", HorarioTipo);
        if (HorarioTipo === 0) {
          // POR DIA
          if (Lunes) {
            data.append("lunes", 1);
            data.append("HoraInicioLunes", HorarioInicioLunes);
            data.append("HoraFinLunes", HorarioFinLunes);
          }

          if (Martes) {
            data.append("martes", 1);
            data.append("HoraInicioMartes", HorarioInicioMartes);
            data.append("HoraFinMartes", HorarioFinMartes);
          }

          if (Miercoles) {
            data.append("miercoles", 1);
            data.append("HoraInicioMiercoles", HorarioInicioMiercoles);
            data.append("HoraFinMiercoles", HorarioFinMiercoles);
          }

          if (Jueves) {
            data.append("jueves", 1);
            data.append("HoraInicioJueves", HorarioInicioJueves);
            data.append("HoraFinJueves", HorarioFinJueves);
          }

          if (Viernes) {
            data.append("viernes", 1);
            data.append("HoraInicioViernes", HorarioInicioViernes);
            data.append("HoraFinViernes", HorarioFinViernes);
          }

          if (Sabado) {
            data.append("sabado", 1);
            data.append("HoraInicioSabado", HorarioInicioSabado);
            data.append("HoraFinSabado", HorarioFinSabado);
          }

          if (Domingo) {
            data.append("domingo", 1);
            data.append("HoraInicioDomingo", HorarioInicioDomingo);
            data.append("HoraFinDomingo", HorarioFinDomingo);
          }
        } else {
          // POR SEMANA
          if (Lunes) {
            data.append("lunes", 1);
            data.append("HoraInicioLunes", HorarioInicioSemana);
            data.append("HoraFinLunes", HorarioFinSemana);
          }

          if (Martes) {
            data.append("martes", 1);
            data.append("HoraInicioMartes", HorarioInicioSemana);
            data.append("HoraFinMartes", HorarioFinSemana);
          }

          if (Miercoles) {
            data.append("miercoles", 1);
            data.append("HoraInicioMiercoles", HorarioInicioSemana);
            data.append("HoraFinMiercoles", HorarioFinSemana);
          }

          if (Jueves) {
            data.append("jueves", 1);
            data.append("HoraInicioJueves", HorarioInicioSemana);
            data.append("HoraFinJueves", HorarioFinSemana);
          }

          if (Viernes) {
            data.append("viernes", 1);
            data.append("HoraInicioViernes", HorarioInicioSemana);
            data.append("HoraFinViernes", HorarioFinSemana);
          }

          if (Sabado) {
            data.append("sabado", 1);
            data.append("HoraInicioSabado", HorarioInicioSemana);
            data.append("HoraFinSabado", HorarioFinSemana);
          }

          if (Domingo) {
            data.append("domingo", 1);
            data.append("HoraInicioDomingo", HorarioInicioSemana);
            data.append("HoraFinDomingo", HorarioFinSemana);
          }
        }

        // Citas
        data.append("Cita", Citas ? 1 : 0);
        data.append("CitaModificar", Citas ? 1 : 0);
        data.append("CitaVisualizar", Citas ? 1 : 0);

        // Contactos
        data.append("Contactos", Contactos ? 1 : 0);
        data.append("ContactoModificar", Contactos ? 1 : 0);
        data.append("ContactoVisualizar", Contactos ? 1 : 0);


        // Anuncios
        data.append("Anuncios", Anuncios ? 1 : 0);
        data.append("AnunciosModificar", Anuncios ? 1 : 0);
        data.append("AnunciosVisualizar", Anuncios ? 1 : 0);

        // Chat
        data.append("Chat", Chat ? 1 : 0);


        // Notificaciones
        data.append("Notificaciones", Notificaciones ? 1 : 0);
        data.append("NotificacionesVisualizar", Notificaciones ? 1 : 0);

        // Consultas
        data.append("Consultas", Consultas ? 1 : 0);
        data.append("ConsultasModificar", Consultas ? 1 : 0);
        data.append("ConsultasVisualizar", Consultas ? 1 : 0);

        // Recetas
        data.append("Recetas", Recetas ? 1 : 0);
        data.append("RecetasModificar", Recetas ? 1 : 0);
        data.append("RecetasVisualizar", Recetas ? 1 : 0);

        // Estudios
        data.append("Estudios", Estudios ? 1 : 0);
        data.append("EstudiosModificar", Estudios ? 1 : 0);
        data.append("EstudiosVisualizar", Estudios ? 1 : 0);

        // Historial
        data.append("Historial", Historial ? 1 : 0);
        data.append("HistorialModificar", Historial ? 1 : 0);
        data.append("HistorialVisualizar", Historial ? 1 : 0);

        // PuntoVenta
        data.append("PV", PuntoVenta ? 1 : 0);
        data.append("PVModificar", PuntoVenta ? 1 : 0);
        data.append("PVVisualizar", PuntoVenta ? 1 : 0);


        // Ingresos
        data.append("Ingresos", Ingresos ? 1 : 0);
        data.append("IngresosModificar", Ingresos ? 1 : 0);
        data.append("IngresosVisualizar", Ingresos ? 1 : 0);

        // Vinculacion
        data.append("Vinculacion", Vinculacion ? 1 : 0);
        data.append("VinculacionModificar", Vinculacion ? 1 : 0);
        data.append("VinculacionVisualizar", Vinculacion ? 1 : 0);

        let response = await editAssitant_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cambios guardados");
          setActiveMoadalAsist(0);
          CloseModalDAE(true);
          setInputsEditable(true);
          getAsistentes();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Debe habilitar al menos un permiso");
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debe asignar al menos un horario");
    }
  }

  async function addAssistant() {
    if (
      Lunes ||
      Martes ||
      Miercoles ||
      Jueves ||
      Viernes ||
      Sabado ||
      Domingo
    ) {
      if (Citas ||
        Contactos ||
        Anuncios ||
        Chat ||
        Notificaciones ||
        PuntoVenta ||
        Ingresos ||
        Vinculacion) {
        let data = new FormData();
        // console.log(IdAssistant);
        // console.log(EstadoAssistant);
        // console.log(HorarioTipo);
        data.append("id", IdAssistant);
        data.append("Estado", EstadoAssistant ? 1 : 0);
        data.append("tipoHorario", HorarioTipo);
        if (HorarioTipo === 0) {
          // POR DIA
          if (Lunes) {
            data.append("lunes", 1);
            data.append("HoraInicioLunes", HorarioInicioLunes);
            data.append("HoraFinLunes", HorarioFinLunes);
          }

          if (Martes) {
            data.append("martes", 1);
            data.append("HoraInicioMartes", HorarioInicioMartes);
            data.append("HoraFinMartes", HorarioFinMartes);
          }

          if (Miercoles) {
            data.append("miercoles", 1);
            data.append("HoraInicioMiercoles", HorarioInicioMiercoles);
            data.append("HoraFinMiercoles", HorarioFinMiercoles);
          }

          if (Jueves) {
            data.append("jueves", 1);
            data.append("HoraInicioJueves", HorarioInicioJueves);
            data.append("HoraFinJueves", HorarioFinJueves);
          }

          if (Viernes) {
            data.append("viernes", 1);
            data.append("HoraInicioViernes", HorarioInicioViernes);
            data.append("HoraFinViernes", HorarioFinViernes);
          }

          if (Sabado) {
            data.append("sabado", 1);
            data.append("HoraInicioSabado", HorarioInicioSabado);
            data.append("HoraFinSabado", HorarioFinSabado);
          }

          if (Domingo) {
            data.append("domingo", 1);
            data.append("HoraInicioDomingo", HorarioInicioDomingo);
            data.append("HoraFinDomingo", HorarioFinDomingo);
          }
        } else {
          // POR SEMANA
          // console.log(HorarioInicioSemana);
          // console.log(HorarioFinSemana);
          if (Lunes) {
            data.append("lunes", 1);
            data.append("HoraInicioLunes", HorarioInicioSemana);
            data.append("HoraFinLunes", HorarioFinSemana);
          }

          if (Martes) {
            data.append("martes", 1);
            data.append("HoraInicioMartes", HorarioInicioSemana);
            data.append("HoraFinMartes", HorarioFinSemana);
          }

          if (Miercoles) {
            data.append("miercoles", 1);
            data.append("HoraInicioMiercoles", HorarioInicioSemana);
            data.append("HoraFinMiercoles", HorarioFinSemana);
          }

          if (Jueves) {
            data.append("jueves", 1);
            data.append("HoraInicioJueves", HorarioInicioSemana);
            data.append("HoraFinJueves", HorarioFinSemana);
          }

          if (Viernes) {
            data.append("viernes", 1);
            data.append("HoraInicioViernes", HorarioInicioSemana);
            data.append("HoraFinViernes", HorarioFinSemana);
          }

          if (Sabado) {
            data.append("sabado", 1);
            data.append("HoraInicioSabado", HorarioInicioSemana);
            data.append("HoraFinSabado", HorarioFinSemana);
          }

          if (Domingo) {
            data.append("domingo", 1);
            data.append("HoraInicioDomingo", HorarioInicioSemana);
            data.append("HoraFinDomingo", HorarioFinSemana);
          }
        }

        // Citas
        data.append("Cita", Citas ? 0 : 1);
        data.append("CitaModificar", Citas ? 1 : 0);
        data.append("CitaVisualizar", Citas ? 1 : 0);

        // Contactos
        data.append("Contactos", Contactos ? 0 : 1);
        data.append("ContactoModificar", Contactos ? 1 : 0);
        data.append("ContactoVisualizar", Contactos ? 1 : 0);


        // Anuncios
        data.append("Anuncios", Anuncios ? 0 : 1);
        data.append("AnunciosModificar", Anuncios ? 1 : 0);
        data.append("AnunciosVisualizar", Anuncios ? 1 : 0);

        // Chat
        data.append("Chat", Chat ? 0 : 1);


        // Notificaciones
        data.append("Notificaciones", Notificaciones ? 0 : 1);
        data.append("NotificacionesVisualizar", Notificaciones ? 1 : 0);

        // Consultas
        data.append("Consultas", Consultas ? 0 : 1);
        data.append("ConsultasModificar", Consultas ? 1 : 0);
        data.append("ConsultasVisualizar", Consultas ? 1 : 0);

        // Recetas
        data.append("Recetas", Recetas ? 0 : 1);
        data.append("RecetasModificar", Recetas ? 1 : 0);
        data.append("RecetasVisualizar", Recetas ? 1 : 0);

        // Estudios
        data.append("Estudios", Estudios ? 0 : 1);
        data.append("EstudiosModificar", Estudios ? 1 : 0);
        data.append("EstudiosVisualizar", Estudios ? 1 : 0);

        // Historial
        data.append("Historial", Historial ? 0 : 1);
        data.append("HistorialModificar", Historial ? 1 : 0);
        data.append("HistorialVisualizar", Historial ? 1 : 0);

        // PuntoVenta
        data.append("PV", PuntoVenta ? 0 : 1);
        data.append("PVModificar", PuntoVenta ? 1 : 0);
        data.append("PVVisualizar", PuntoVenta ? 1 : 0);


        // Ingresos
        data.append("Ingresos", Ingresos ? 0 : 1);
        data.append("IngresosModificar", Ingresos ? 1 : 0);
        data.append("IngresosVisualizar", Ingresos ? 1 : 0);

        // Vinculacion
        data.append("Vinculacion", Vinculacion ? 0 : 1);
        data.append("VinculacionModificar", Vinculacion ? 1 : 0);
        data.append("VinculacionVisualizar", Vinculacion ? 1 : 0);

        let response = await addAssitant_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Asistente creada");
          setAction(0);
          setActiveMoadalAsist(0);
          CloseModalDAE(true);
          setInputsEditable(true);
          getAsistentes();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Debe habilitar al menos un permiso");
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Debe asignar al menos un horario");
    }
  }

  async function deleteAssistant() {
    let response = await deleteAsistentes_request(IdAssistant);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Asistente eliminada");
      if (ActiveMoadalAsist !== 3) {
        setActiveMoadalAsist(3);
      }
      getAsistentes();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  function clearInputs() {
    setEstadoAssistant(false);

    setHorarioTipo(0);

    setLunes(false);
    setHorarioInicioLunes("");
    setHorarioFinLunes("");
    setMartes(false);
    setHorarioInicioMartes("");
    setHorarioFinMartes("");
    setMiercoles(false);
    setHorarioInicioMiercoles("");
    setHorarioFinMiercoles("");
    setJueves(false);
    setHorarioInicioJueves("");
    setHorarioFinJueves("");
    setViernes(false);
    setHorarioInicioViernes("");
    setHorarioFinViernes("");
    setSabado(false);
    setHorarioInicioSabado("");
    setHorarioFinSabado("");
    setDomingo(false);
    setHorarioInicioDomingo("");
    setHorarioFinDomingo("");

    setCitas(true);

    setContactos(true);

    setAnuncios(true);

    setChat(true);

    setNotificaciones(true);

    setConsultas(true);

    setRecetas(true);

    setEstudios(true);

    setHistorial(true);

    setPuntoVenta(true);

    setIngresos(true);

    setVinculacion(true);
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    AssistantListFilter.forEach((asistente) => {
      if (
        asistente.Asistente.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(asistente);
      }
    });
    setAssistantList(filter);
  }

  function filterContacts(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    AssistantListToAddFilter.forEach((asistente) => {
      if (
        asistente.nombre
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(text.target.value.toLowerCase()) > -1
      ) {
        filter.push(asistente);
      }
    });

    setAssistantListToAdd(filter);
  }

  useEffect(() => {
    getAsistentes();
    getAsistentesToConfig();
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        modulo={"Asistentes"}
        searchBar={
          <div className="EncavezadoAsistentes">
            <div className="AcomodoEncavezado">
              <div>
                <b>Asistentes</b>
              </div>

              <ButtonAntd
                tipo={1}
                style={{ width: "100px" }}
                onClick={() => {
                  if (AssistantListToAdd.length > 0) {
                    OpenModal(true);
                    if (ActiveMoadalAsist === 1) {
                      setActiveMoadalAsist(3);
                      clearInputs();
                    }
                  } else {
                    setOpen(true);
                    setTypeMessage("warning");
                    setMessage("No cuenta con asistentes para configurar");
                  }
                }}
                children={
                  <Row
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      color: "white",
                      widows: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Nueva
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Row>
                }
              />
            </div>
            <Input
              placeholder="Buscar por nombre"
              type="text"
              className="inputblue"
              style={{ width: "31%" }}
              onChange={filterList}
            />
          </div>
        }
      />

      <Content
        className="site-layout"
        style={{
          padding: ".5% 3%",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <AsistentesCard
            openModalDAE={openModalDAE}
            CloseModalDAE={CloseModalDAE}
            setAccionesAsist={setAccionesAsist}
            setActiveMoadalAsist={setActiveMoadalAsist}
            AssistantList={AssistantList}
            setIdAssistant={setIdAssistant}
            detailAssistant={detailAssistant}
            deleteAssistant={deleteAssistant}
            setAction={setAction}
            setInputsEditable={setInputsEditable}
          />
        )}
      </Content>

      <Modal isOpen={isOpenModal} closeModal={CloseModal} styleDet={styleDet}>
        <div className="TopModal">
          Nuevo Asistente
          <CloseIcon
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={CloseModal}
          />
        </div>
        <div className="ModalListCfiltro">
          <p>Nombre o ID</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />

          <div className="contCardModal">
            <AddAsistent
              openModalDAE={openModalDAE}
              setAccionesAsist={setAccionesAsist}
              setActiveMoadalAsist={setActiveMoadalAsist}
              CloseModal={CloseModal}
              AssistantListToAdd={AssistantListToAdd}
              setIdAssistant={setIdAssistant}
              setInputsEditable={setInputsEditable}
              setAction={setAction}
              setInfoToAddAssistant={setInfoToAddAssistant}
            />
          </div>
        </div>
      </Modal>
      <ModalDAE
        isOpen={isopenModalDAE}
        // isOpen={true}
        CloseModal={CloseModalDAE}
        styleDet={styleDet}
      >
        {/* {ActiveMoadalAsist === 1 && ( */}
        <div className="DetalleEditAsistent">
          <div className="EncabAsistente">
            {Action === 1 ? <>Asistente</> : <>Nuevo asistente</>}
            <CloseIcon
              style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() => {
                if (Action === 1) {
                  // console.log();

                  if (!InputsEditable) {
                    setInputsEditable(true);
                    setAccionesAsist(0);
                  } else {
                    CloseModalDAE(true);
                    // setActiveMoadalAsist(0);
                  }
                } else {
                  CloseModalDAE(true);
                  // setActiveMoadalAsist(0);
                }
              }}
            />
          </div>
          {loaderModal ? (
            <LoaderModals className={"DetalleContenidoAsist"} />
          ) : (
            <div className="DetalleContenidoAsist">
              <header style={{ position: "sticky", top: "0", zIndex: "999" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",

                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {AccionesAsist === 0 ? (
                    <Button
                      className="GeneralBoton"
                      style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90px",
                      }}
                      onClick={() => {
                        setAccionesAsist(1);
                        setInputsEditable(false);
                        setAction(1);
                      }}
                    >
                      {" "}
                      Editar
                      <Edit style={{ fontSize: 15 }} />
                    </Button>
                  ) : (
                    <Button
                      className="GeneralBoton"
                      style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100px",
                      }}
                      onClick={() => {
                        if (Action === 0) {
                          addAssistant();
                        } else {
                          editAssistant();
                        }
                      }}
                      title="Guardar cambios"
                    >
                      Guardar
                      <CheckCircleIcon style={{ fontSize: 15 }} />
                    </Button>
                  )}
                </div>
              </header>
              <div style={{ textAlign: "center", margin: "1% 0" }}>
                {Action === 0 ? (
                  <img
                    className="imgDetalleAsist"
                    src={
                      !!InfoToAddAssistant.imagen
                        ? "https://" + InfoToAddAssistant.imagen
                        : Iconos[0]
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="imgDetalleAsist"
                    src={
                      !!DetailAssistant.Imagen_url
                        ? "https://" + DetailAssistant.Imagen_url
                        : Iconos[0]
                    }
                    alt=""
                  />
                )}
                <div>
                  {Action === 0 ? (
                    <b>{InfoToAddAssistant.nombre}</b>
                  ) : (
                    <b>{DetailAssistant.Nombre}</b>
                  )}
                </div>
              </div>

              <div>
                {AccionesAsist === 0 ? (
                  <div>
                    <div>
                      <b>Estado:</b>
                      <span style={{ color: "#148f9f", marginLeft: "3px" }}>
                        {DetailAssistant.Estado}
                      </span>
                    </div>
                    <b>ID:</b>
                    <span style={{ marginLeft: "3px" }}>
                      {DetailAssistant.IdAsistente}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "3px",
                        }}
                        type="checkbox"
                        checked={EstadoAssistant}
                        onChange={(e) => {
                          // console.log(e.target.checked);
                          setEstadoAssistant(e.target.checked);
                        }}
                      />
                      <span style={{ color: "#148f9f" }}>
                        Estatus activo de asistente
                      </span>
                    </div>
                    <div>
                      <div>
                        {/*Seleccionar rol de usuario*/}

                        <label>Selecciona tu tipo de horario:</label>
                        <select
                          // onChange={() => }
                          onChange={(e) => {
                            // console.log(e.target.value);
                            setHorarioTipo(parseInt(e.target.value));
                            // setHorario(0);
                            // if (document.getElementById("Horario-tipo").value === "HorarioXdia") {
                            //   setHorarioTipo(1);
                            //   setHorario(0);
                            // } else {
                            //   setHorarioTipo(0);
                            //   setHorario(0);
                            // }
                          }}
                          style={{ height: "30px", width: "100%" }}
                          className="inputblue"
                          name=""
                          id="Horario-tipo"
                          value={HorarioTipo}
                        >
                          <option
                            style={{ color: "#148f9f" }}
                            value=""
                            disabled
                            selected
                          >
                            Selecciona una opción
                          </option>
                          <option value="0">Por día</option>
                          <option value="1">Por semana</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="AcordionAsist"
                  onClick={() => {
                    if (Horario === 0) {
                      setHorario(1);
                    } else {
                      setHorario(0);
                    }
                  }}
                >
                  <h3>Horario</h3>
                  {Horario === 0 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </div>
                <div
                  style={{
                    margin: "1% 0",
                    border: "1px solid #eaeef0",
                    width: "100%",
                  }}
                >
                  {Horario === 0 && (
                    <div>
                      {HorarioTipo === 3 && <div></div>}
                      {HorarioTipo === 0 && (
                        <div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Lunes}
                                  onChange={(e) => {
                                    if (!Lunes) {
                                      setLunes(true);
                                      setHorarioInicioLunes("9:00 AM");
                                      setHorarioFinLunes("4:00 PM");
                                    } else {
                                      setLunes(false);
                                      setHorarioInicioLunes("");
                                      setHorarioFinLunes("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Lunes
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioLunes &&
                                    HorarioInicioLunes !== "00:00:00"
                                    ? dayjs(HorarioInicioLunes, "h:mm A")
                                    : "",
                                  !!HorarioFinLunes &&
                                    HorarioFinLunes !== "00:00:00"
                                    ? dayjs(HorarioFinLunes, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioLunes(e[0].format("h:mm A"));
                                  setHorarioFinLunes(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Lunes) {
                                    setLunes(true);
                                    setHorarioInicioLunes("9:00 AM");
                                    setHorarioFinLunes("4:00 PM");
                                  } else {
                                    setLunes(false);
                                    setHorarioInicioLunes("");
                                    setHorarioFinLunes("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Lunes}
                                />)}

                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Lunes</h4>
                              </Row> */}
                          </div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Martes}
                                  onChange={(e) => {
                                    if (!Martes) {
                                      setMartes(true);
                                      setHorarioInicioMartes("9:00 AM");
                                      setHorarioFinMartes("4:00 PM");
                                    } else {
                                      setMartes(false);
                                      setHorarioInicioMartes("");
                                      setHorarioFinMartes("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Martes
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioMartes &&
                                    HorarioInicioMartes !== "00:00:00"
                                    ? dayjs(HorarioInicioMartes, "h:mm A")
                                    : "",
                                  !!HorarioFinMartes &&
                                    HorarioFinMartes !== "00:00:00"
                                    ? dayjs(HorarioFinMartes, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioMartes(e[0].format("h:mm A"));
                                  setHorarioFinMartes(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Martes) {
                                    setMartes(true);
                                    setHorarioInicioMartes("9:00 AM");
                                    setHorarioFinMartes("4:00 PM");
                                  } else {
                                    setMartes(false);
                                    setHorarioInicioMartes("");
                                    setHorarioFinMartes("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Martes}
                                />)}
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Martes</h4>
                              </Row> */}
                          </div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Miercoles}
                                  onChange={(e) => {
                                    if (!Miercoles) {
                                      setMiercoles(true);
                                      setHorarioInicioMiercoles("9:00 AM");
                                      setHorarioFinMiercoles("4:00 PM");
                                    } else {
                                      setMiercoles(false);
                                      setHorarioInicioMiercoles("");
                                      setHorarioFinMiercoles("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Miercoles
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioMiercoles &&
                                    HorarioInicioMiercoles !== "00:00:00"
                                    ? dayjs(HorarioInicioMiercoles, "h:mm A")
                                    : "",
                                  !!HorarioFinMiercoles &&
                                    HorarioFinMiercoles !== "00:00:00"
                                    ? dayjs(HorarioFinMiercoles, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioMiercoles(
                                    e[0].format("h:mm A")
                                  );
                                  setHorarioFinMiercoles(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Miercoles) {
                                    setMiercoles(true);
                                    setHorarioInicioMiercoles("9:00 AM");
                                    setHorarioFinMiercoles("4:00 PM");
                                  } else {
                                    setMiercoles(false);
                                    setHorarioInicioMiercoles("");
                                    setHorarioFinMiercoles("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Miercoles}
                                />)}
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Miercoles</h4>
                              </Row>
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[!!HorarioInicioMiercoles && HorarioInicioMiercoles !== "00:00:00" ? dayjs(HorarioInicioMiercoles, "h:mm A") : "",
                                !!HorarioFinMiercoles && HorarioFinMiercoles !== "00:00:00" ? dayjs(HorarioFinMiercoles, "h:mm A") : ""
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioMiercoles(e[0].format("h:mm A"));
                                  setHorarioFinMiercoles(e[1].format("h:mm A"));
                                }}
                                disabled={!InputsEditable ? false : InputsEditable}
                              /> */}
                          </div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Jueves}
                                  onChange={(e) => {
                                    if (!Jueves) {
                                      setJueves(true);
                                      setHorarioInicioJueves("9:00 AM");
                                      setHorarioFinJueves("4:00 PM");
                                    } else {
                                      setJueves(false);
                                      setHorarioInicioJueves("");
                                      setHorarioFinJueves("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Jueves
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioJueves &&
                                    HorarioInicioJueves !== "00:00:00"
                                    ? dayjs(HorarioInicioJueves, "h:mm A")
                                    : "",
                                  !!HorarioFinJueves &&
                                    HorarioFinJueves !== "00:00:00"
                                    ? dayjs(HorarioFinJueves, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioJueves(e[0].format("h:mm A"));
                                  setHorarioFinJueves(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Jueves) {
                                    setJueves(true);
                                    setHorarioInicioJueves("9:00 AM");
                                    setHorarioFinJueves("4:00 PM");
                                  } else {
                                    setJueves(false);
                                    setHorarioInicioJueves("");
                                    setHorarioFinJueves("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Jueves}
                                />)}
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Jueves</h4>
                              </Row>
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[!!HorarioInicioJueves && HorarioInicioJueves !== "00:00:00" ? dayjs(HorarioInicioJueves, "h:mm A") : "",
                                !!HorarioFinJueves && HorarioFinJueves !== "00:00:00" ? dayjs(HorarioFinJueves, "h:mm A") : ""
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioJueves(e[0].format("h:mm A"));
                                  setHorarioFinJueves(e[1].format("h:mm A"));
                                }}
                                disabled={!InputsEditable ? false : InputsEditable}
                              /> */}
                          </div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Viernes}
                                  onChange={(e) => {
                                    if (!Viernes) {
                                      setViernes(true);
                                      setHorarioInicioViernes("9:00 AM");
                                      setHorarioFinViernes("4:00 PM");
                                    } else {
                                      setViernes(false);
                                      setHorarioInicioViernes("");
                                      setHorarioFinViernes("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Viernes
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioViernes &&
                                    HorarioInicioViernes !== "00:00:00"
                                    ? dayjs(HorarioInicioViernes, "h:mm A")
                                    : "",
                                  !!HorarioFinViernes &&
                                    HorarioFinViernes !== "00:00:00"
                                    ? dayjs(HorarioFinViernes, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioViernes(
                                    e[0].format("h:mm A")
                                  );
                                  setHorarioFinViernes(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Viernes) {
                                    setViernes(true);
                                    setHorarioInicioViernes("9:00 AM");
                                    setHorarioFinViernes("4:00 PM");
                                  } else {
                                    setViernes(false);
                                    setHorarioInicioViernes("");
                                    setHorarioFinViernes("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Viernes}
                                />)}
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Viernes</h4>
                              </Row>
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[!!HorarioInicioViernes && HorarioInicioViernes !== "00:00:00" ? dayjs(HorarioInicioViernes, "h:mm A") : "",
                                !!HorarioFinViernes && HorarioFinViernes !== "00:00:00" ? dayjs(HorarioFinViernes, "h:mm A") : ""
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioViernes(e[0].format("h:mm A"));
                                  setHorarioFinViernes(e[1].format("h:mm A"));
                                }}
                                disabled={!InputsEditable ? false : InputsEditable}
                              /> */}
                          </div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Sabado}
                                  onChange={(e) => {
                                    if (!Sabado) {
                                      setSabado(true);
                                      setHorarioInicioSabado("9:00 AM");
                                      setHorarioFinSabado("4:00 PM");
                                    } else {
                                      setSabado(false);
                                      setHorarioInicioSabado("");
                                      setHorarioFinSabado("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Sábado
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioSabado &&
                                    HorarioInicioSabado !== "00:00:00"
                                    ? dayjs(HorarioInicioSabado, "h:mm A")
                                    : "",
                                  !!HorarioFinSabado &&
                                    HorarioFinSabado !== "00:00:00"
                                    ? dayjs(HorarioFinSabado, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioSabado(e[0].format("h:mm A"));
                                  setHorarioFinSabado(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Sabado) {
                                    setSabado(true);
                                    setHorarioInicioSabado("9:00 AM");
                                    setHorarioFinSabado("4:00 PM");
                                  } else {
                                    setSabado(false);
                                    setHorarioInicioSabado("");
                                    setHorarioFinSabado("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Sabado}
                                />)}
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Sábado</h4>
                              </Row>
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[!!HorarioInicioSabado && HorarioInicioSabado !== "00:00:00" ? dayjs(HorarioInicioSabado, "h:mm A") : "",
                                !!HorarioFinSabado && HorarioFinSabado !== "00:00:00" ? dayjs(HorarioFinSabado, "h:mm A") : ""
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioSabado(e[0].format("h:mm A"));
                                  setHorarioFinSabado(e[1].format("h:mm A"));
                                }}
                                disabled={!InputsEditable ? false : InputsEditable}
                              /> */}
                          </div>
                          <div>
                            <Campos
                              label={
                                <Checkbox
                                  disabled={InputsEditable}
                                  checked={Domingo}
                                  onChange={(e) => {
                                    if (!Domingo) {
                                      setDomingo(true);
                                      setHorarioInicioDomingo("9:00 AM");
                                      setHorarioFinDomingo("4:00 PM");
                                    } else {
                                      setDomingo(false);
                                      setHorarioInicioDomingo("");
                                      setHorarioFinDomingo("");
                                    }
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Domingo
                                </Checkbox>
                              }
                            >
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[
                                  !!HorarioInicioDomingo &&
                                    HorarioInicioDomingo !== "00:00:00"
                                    ? dayjs(HorarioInicioDomingo, "h:mm A")
                                    : "",
                                  !!HorarioFinDomingo &&
                                    HorarioFinDomingo !== "00:00:00"
                                    ? dayjs(HorarioFinDomingo, "h:mm A")
                                    : "",
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioDomingo(
                                    e[0].format("h:mm A")
                                  );
                                  setHorarioFinDomingo(e[1].format("h:mm A"));
                                }}
                                disabled={
                                  !InputsEditable ? false : InputsEditable
                                }
                              />
                            </Campos>
                            {/* <Row>
                                {!InputsEditable && (<input type="checkbox" onChange={() => {
                                  if (!Domingo) {
                                    setDomingo(true);
                                    setHorarioInicioDomingo("9:00 AM");
                                    setHorarioFinDomingo("4:00 PM");
                                  } else {
                                    setDomingo(false);
                                    setHorarioInicioDomingo("");
                                    setHorarioFinDomingo("");
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Domingo}
                                />)}
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "4%" }}>Domingo</h4>
                              </Row>
                              <TimePicker.RangePicker
                                className="DateAsistent"
                                value={[!!HorarioInicioDomingo && HorarioInicioDomingo !== "00:00:00" ? dayjs(HorarioInicioDomingo, "h:mm A") : "",
                                !!HorarioFinDomingo && HorarioFinDomingo !== "00:00:00" ? dayjs(HorarioFinDomingo, "h:mm A") : ""
                                ]}
                                use12Hours={true}
                                format="h:mm A"
                                onChange={(e) => {
                                  setHorarioInicioDomingo(e[0].format("h:mm A"));
                                  setHorarioFinDomingo(e[1].format("h:mm A"));
                                }}
                                disabled={!InputsEditable ? false : InputsEditable}
                              /> */}
                          </div>
                        </div>
                      )}
                      {HorarioTipo === 1 && (
                        <div className="ContenidoHorarioCalendarioAsist">
                          {/* <div className="AsistHorarioCalendario"> */}
                          <Row>
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Lunes}
                              onChange={(e) => {
                                if (!Lunes) {
                                  if (
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setLunes(true);
                                  } else {
                                    setLunes(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setLunes(false);
                                  } else {
                                    setLunes(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Lun
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Lunes) {
                                    if (Martes || Miercoles || Jueves || Viernes || Sabado || Domingo) {
                                      setLunes(true);
                                    } else {
                                      setLunes(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Martes || Miercoles || Jueves || Viernes || Sabado || Domingo) {
                                      setLunes(false);
                                    } else {
                                      setLunes(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Lunes}
                                  // disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                  disabled={!InputsEditable ? false : InputsEditable}
                                />
                                <span>Lun</span>
                              </div> */}
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Martes}
                              onChange={(e) => {
                                if (!Martes) {
                                  if (
                                    Lunes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setMartes(true);
                                  } else {
                                    setMartes(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Lunes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setMartes(false);
                                  } else {
                                    setMartes(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Mar
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Martes) {
                                    if (Lunes || Miercoles || Jueves || Viernes || Sabado || Domingo) {
                                      setMartes(true);
                                    } else {
                                      setMartes(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Lunes || Miercoles || Jueves || Viernes || Sabado || Domingo) {
                                      setMartes(false);
                                    } else {
                                      setMartes(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Martes}
                                  disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                />
                                <span>Mar</span>
                              </div> */}
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Miercoles}
                              onChange={(e) => {
                                if (!Miercoles) {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setMiercoles(true);
                                  } else {
                                    setMiercoles(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setMiercoles(false);
                                  } else {
                                    setMiercoles(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Mie
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Miercoles) {
                                    if (Lunes || Martes || Jueves || Viernes || Sabado || Domingo) {
                                      setMiercoles(true);
                                    } else {
                                      setMiercoles(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Lunes || Martes || Jueves || Viernes || Sabado || Domingo) {
                                      setMiercoles(false);
                                    } else {
                                      setMiercoles(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Miercoles}
                                  disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                />
                                <span>Mie</span>
                              </div> */}
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Jueves}
                              onChange={(e) => {
                                if (!Jueves) {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setJueves(true);
                                  } else {
                                    setJueves(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Viernes ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setJueves(false);
                                  } else {
                                    setJueves(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Juev
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Jueves) {
                                    if (Lunes || Martes || Miercoles || Viernes || Sabado || Domingo) {
                                      setJueves(true);
                                    } else {
                                      setJueves(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Lunes || Martes || Miercoles || Viernes || Sabado || Domingo) {
                                      setJueves(false);
                                    } else {
                                      setJueves(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Jueves}
                                  disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                />
                                <span>Juev</span>
                              </div> */}
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Viernes}
                              onChange={(e) => {
                                if (!Viernes) {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setViernes(true);
                                  } else {
                                    setViernes(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Sabado ||
                                    Domingo
                                  ) {
                                    setViernes(false);
                                  } else {
                                    setViernes(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Vie
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Viernes) {
                                    if (Lunes || Martes || Miercoles || Jueves || Sabado || Domingo) {
                                      setViernes(true);
                                    } else {
                                      setViernes(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Lunes || Martes || Miercoles || Jueves || Sabado || Domingo) {
                                      setViernes(false);
                                    } else {
                                      setViernes(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Viernes}
                                  disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                />
                                <span>Vie</span>
                              </div> */}
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Sabado}
                              onChange={(e) => {
                                if (!Sabado) {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Domingo
                                  ) {
                                    setSabado(true);
                                  } else {
                                    setSabado(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Domingo
                                  ) {
                                    setSabado(false);
                                  } else {
                                    setSabado(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Sab
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Sabado) {
                                    if (Lunes || Martes || Miercoles || Jueves || Viernes || Domingo) {
                                      setSabado(true);
                                    } else {
                                      setSabado(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Lunes || Martes || Miercoles || Jueves || Viernes || Domingo) {
                                      setSabado(false);
                                    } else {
                                      setSabado(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Sabado}
                                  disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                />
                                <span>Sab</span>
                              </div> */}
                            <Checkbox
                              disabled={InputsEditable}
                              checked={Domingo}
                              onChange={(e) => {
                                if (!Domingo) {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado
                                  ) {
                                    setDomingo(true);
                                  } else {
                                    setDomingo(true);
                                    setHorarioInicioSemana("9:00 AM");
                                    setHorarioFinSemana("4:00 PM");
                                  }
                                } else {
                                  if (
                                    Lunes ||
                                    Martes ||
                                    Miercoles ||
                                    Jueves ||
                                    Viernes ||
                                    Sabado
                                  ) {
                                    setDomingo(false);
                                  } else {
                                    setDomingo(false);
                                    setHorarioInicioSemana("");
                                    setHorarioFinSemana("");
                                  }
                                }
                              }}
                              style={{ fontSize: "16px", fontWeight: "700" }}
                            >
                              Dom
                            </Checkbox>
                            {/* <div className="InputySpanflexDos">
                                <input type="checkbox" onChange={() => {
                                  if (!Domingo) {
                                    if (Lunes || Martes || Miercoles || Jueves || Viernes || Sabado) {
                                      setDomingo(true);
                                    } else {
                                      setDomingo(true);
                                      setHorarioInicioSemana("9:00 AM");
                                      setHorarioFinSemana("4:00 PM");
                                    }
                                  } else {
                                    if (Lunes || Martes || Miercoles || Jueves || Viernes || Sabado) {
                                      setDomingo(false);
                                    } else {
                                      setDomingo(false);
                                      setHorarioInicioSemana("");
                                      setHorarioFinSemana("");
                                    }
                                  }
                                }}
                                  style={{ width: "15px" }}
                                  checked={Domingo}
                                  disabled={Action === 1 ? !InputsEditable ? false : InputsEditable : InputsEditable}
                                />
                                <span>Dom</span>
                              </div> */}
                          </Row>
                          <TimePicker.RangePicker
                            className="DateAsistent"
                            value={[
                              !!HorarioInicioSemana &&
                                HorarioInicioSemana !== "00:00:00"
                                ? dayjs(HorarioInicioSemana, "h:mm A")
                                : "",
                              !!HorarioFinSemana &&
                                HorarioFinSemana !== "00:00:00"
                                ? dayjs(HorarioFinSemana, "h:mm A")
                                : "",
                            ]}
                            use12Hours={true}
                            format="h:mm A"
                            onChange={(e) => {
                              setHorarioInicioSemana(e[0].format("h:mm A"));
                              setHorarioFinSemana(e[1].format("h:mm A"));
                            }}
                            disabled={
                              !InputsEditable
                                ? Lunes ||
                                  Martes ||
                                  Miercoles ||
                                  Jueves ||
                                  Viernes ||
                                  Sabado ||
                                  Domingo
                                  ? false
                                  : true
                                : true
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div
                  className="AcordionAsist"
                  onClick={() => {
                    if (Permisos === 0) {
                      setPermisos(1);
                    } else {
                      setPermisos(0);
                    }
                  }}
                >
                  <h3>Permisos</h3>
                  {Permisos === 0 ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </div>
                {Permisos === 0 && ( // Aqui empiezan los permisos de los modulos---------------------------
                  <div style={{ paddingLeft: "3%", paddingRight: "3%" }}>
                    {/* Modulo de citas------------------------------------------------------------------------- */}
                    <div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Citas</h4>
                        <Switch style={{ background: `${Citas ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={Citas} onChange={(e) => {
                          setCitas(e);
                        }} />
                      </div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Contactos</h4>
                        <Switch style={{ background: `${Contactos ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={Contactos} onChange={(e) => {
                          setContactos(e);
                        }} />
                      </div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Chat</h4>
                        <Switch style={{ background: `${Chat ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={Chat} onChange={(e) => {
                          setChat(e);
                        }} />
                      </div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Notificaciones</h4>
                        <Switch style={{ background: `${Notificaciones ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={Notificaciones} onChange={(e) => {
                          setNotificaciones(e);
                        }} />
                      </div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Punto de venta</h4>
                        <Switch style={{ background: `${PuntoVenta ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={PuntoVenta} onChange={(e) => {
                          setPuntoVenta(e);
                        }} />
                      </div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Ingresos y egresos</h4>
                        <Switch style={{ background: `${Ingresos ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={Ingresos} onChange={(e) => {
                          setIngresos(e);
                        }} />
                      </div>
                      <br />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4>Vinculación</h4>
                        <Switch style={{ background: `${Vinculacion ? "#148f9f" : ""}` }} disabled={InputsEditable} checked={Vinculacion} onChange={(e) => {
                          setVinculacion(e);
                        }} />
                      </div>
                      {/* <div
                        className="AcordionAsist"
                        onClick={() => {
                          if (ModuloCitas === 0) {
                            setModuloCitas(1);
                          } else {
                            setModuloCitas(0);
                          }
                        }}
                      >
                        <h4>Citas</h4>
                        {ModuloCitas === 0 ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </div>
                      {ModuloCitas === 0 && (
                        <>
                          <div className="OpPermisosAsist">
                            <div className="InputySpanflex">
                              <input
                                type="checkbox"
                                disabled={InputsEditable}
                                checked={CitasModificar}
                                onChange={() => {
                                  if (CitasVisualizar || Citas) {
                                    setCitasModificar(true);
                                    setCitasVisualizar(false);
                                    setCitas(false);
                                  }
                                }}
                              />
                              <span>Todo</span>
                            </div>
                            <div className="InputySpanflex">
                              <input
                                type="checkbox"
                                disabled={InputsEditable}
                                checked={CitasVisualizar}
                                onChange={() => {
                                  if (CitasModificar || Citas) {
                                    setCitasModificar(false);
                                    setCitasVisualizar(true);
                                    setCitas(false);
                                  }
                                }}
                              />
                              <span>Lectura</span>
                            </div>
                          </div>
                          <div className="OpPermisosAsist">
                            <div className="InputySpanflex">
                              <input
                                type="checkbox"
                                disabled={InputsEditable}
                                checked={Citas}
                                onChange={() => {
                                  if (CitasModificar || CitasVisualizar) {
                                    setCitasModificar(false);
                                    setCitasVisualizar(false);
                                    setCitas(true);
                                  }
                                }}
                              />
                              <span>Sin Permisos</span>
                            </div>
                          </div>
                        </>
                      )} */}
                    </div>

                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </ModalDAE>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
