import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Iconos from "../IconosDeSitio";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from "@mui/icons-material/Search";
import AddIconWhite from "../../Img/Iconos/AgregarBlanco.svg"

// Importando acordiones
import Acordiones from "./HistoriaClinica/AcordionesHistorialClinico";
import "./styles/ExpedienteNuevoDetalle.scss";


// Impotando componentes
import RecetasExpedientes from "./RecetasExpedientes/RecetasExpedientes";
import EstudiosExpediente from "./EstudiosYanalisis/EstudiosExpediente";
import ExpedienteNotasMedicas from "./NotasMedicas/ExpedienteNotasMedicas";
import { getHistoriasClinicas_request } from "../../services/expediente_service";
import NavbarNuevo from "../GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import { Avatar, Button, Image, Input, Row } from "antd";
import { useEffect } from "react";
import { UseModal } from "../../hooks/UseModal";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Loader } from "../GeneralComponents/Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ExpedienteNuevoDetalle() {
  const location = useLocation();
  // console.log(location.state);
  const [isOpenModalAddNota, OpenModalAddNota, CloseModalAddNota] = UseModal(false);
  const [isOpenModalNuevaReceta, OpenModalNuevaReceta, CloseModalNuevaReceta] = UseModal(false);
  const [isOpenModalAddEstudi, OpenModalAddEstudi, CloseModalAddEstudi] = UseModal(false);
  const [isOpenModalDetalleEstudi, OpenModalDetalleEstudi, CloseModalDetalleEstudi] = UseModal(false);

  const [DatosContacto, setDatosContacto] = useState({
    id: !!location.state ? location.state.id : sessionStorage.getItem("idContactoExpediente"),
    nombre: !!location.state ? location.state.nombre : sessionStorage.getItem("nombreContactoExpediente"),
    imagen: !!location.state ? location.state.imagen : sessionStorage.getItem("imagenContactoExpediente")
  });

  // UseState de las pestañas
  const [OpcionSelectExp, setOpcionSelectExp] = useState(1);

  const [Edad, setEdad] = useState(0);
  const [TipoSangre, setTipoSangre] = useState("");

  const [AccionesBoton, setAccionesBoton] = useState(() => { });
  const [ListaPrin, setListaPrin] = useState([]);
  const [ListaFilter, setListaFilter] = useState([]);

  const [NotasEvolucion, setNotasEvolucion] = useState([]);
  const [NotasEvolucionFilter, setNotasEvolucionFilter] = useState([]);

  const [ListaRecetas, setListaRecetas] = useState([]);
  const [ListaRecetasFilter, setListaRecetasFilter] = useState([]);

  const [ListaEstudios, setListaEstudios] = useState([]);
  const [ListaEstudiosFilter, setListaEstudiosFilter] = useState([]);

  const [Historial, setHistorial] = useState({
    ficha: null,
    historial: null
  });

  const [loader, setLoader] = useState(false);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  async function getHistoriaClinica() {
    // console.log(DatosContacto.id);
    setLoader(true);
    let response = await getHistoriasClinicas_request(DatosContacto.id);
    if (response.ok) {
      if (document.domain === "localhost") {
        console.log(response.data);
      }
      setHistorial({ ficha: response.data.ficha, historial: response.data.historial })

      if (response.data.consultas != null) {
        setNotasEvolucion(response.data.consultas);
        setNotasEvolucionFilter(response.data.consultas);
      }
      if (response.data.recetas != null) {
        setListaRecetas(response.data.recetas);
        setListaRecetasFilter(response.data.recetas);
      }

      let array = [];
      if (response.data.estudios != null) {
        response.data.estudios.forEach(element => {
          array.push({ id: element.id, descripcion: !!element.descripcion ? element.descripcion : "Sin descripción", fecha: element.fecha })
        });
      }

      const mapa = new Map();
      const arraySinDuplicados = array.filter((obj) => {
        return !mapa.has(obj.id) && mapa.set(obj.id, 1);
      });
      console.log(arraySinDuplicados);
      setListaEstudios(arraySinDuplicados);
      setListaEstudiosFilter(arraySinDuplicados);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  function filterList(text) {
    // console.log(text.target.value);
    // let palabra = text.target.value;
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    let filter = [];


    if (OpcionSelectExp === 2) {
      NotasEvolucionFilter.forEach((consulta) => {
        // console.log(expediente);
        if (consulta.motivo.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1 ||
          consulta.fecha.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1) {
          filter.push(consulta);
          // console.log(expediente);
        }
      });
      // console.log(filter);
      setNotasEvolucion(filter);
    }

    if (OpcionSelectExp === 3) {
      ListaRecetasFilter.forEach((receta) => {
        // console.log(expediente);
        if (receta.folio.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1 ||
          receta.fecha.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1) {
          filter.push(receta);
          // console.log(expediente);
        }
      });
      setListaRecetas(filter);
    }

    if (OpcionSelectExp === 4) {
      ListaEstudiosFilter.forEach((estudio) => {
        // console.log(expediente);
        if (estudio.descripcion.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1 ||
          estudio.fecha.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(text.target.value.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1) {
          filter.push(estudio);
          // console.log(expediente);
        }
      });

      setListaEstudios(filter);
    }

    // setListaPrin(filter);
  }

  useEffect(() => {
    getHistoriaClinica();
  }, [])
  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <>
            <div className="CebezeraExpedientes">
              <div
                className="Encabesado-Expedientes"
                style={{ alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    alignItems: "center",
                    gap: "1%",
                  }}
                >
                  <div>
                    <Link to="/app/ExpedientesNuevo">
                      <img className="ImgsNotas" src={Iconos[23]} alt="" />
                    </Link>
                  </div>
                  <div>
                    {" "}
                    {/* <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5rem",
                      }}
                      src={!!DatosContacto.imagen ? `https://${DatosContacto.imagen}` : Iconos[0]}
                      alt=""
                    /> */}
                    <Avatar style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size={55}
                      className="GeneralNoImage"
                      src={
                        <Image
                          src={
                            !!DatosContacto.imagen ? `https://${DatosContacto.imagen}` : Iconos[0]
                          }
                        />
                      } />
                  </div>
                  <div>
                    <b style={{ fontSize: "120%" }}>{DatosContacto.nombre}</b>
                    <p style={{ margin: "0", padding: "0" }}>Edad: {Edad !== 0 ? `${Edad} años` : "No espeficada"} </p>
                    <p style={{ margin: "0", padding: "0" }}>Tipo de sangre: {!!TipoSangre ? TipoSangre : "No espeficada"} </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="ExpedNewTabs" style={{ width: "100%" }}>
              <ul>
                <li
                  className={OpcionSelectExp === 1 ? "liSelected" : ""}
                  onClick={() => {
                    setOpcionSelectExp(1);
                  }}
                >
                  Historia clínica
                </li>
                <li
                  className={OpcionSelectExp === 2 ? "liSelected" : ""}
                  onClick={() => {
                    setOpcionSelectExp(2);
                  }}
                >
                  Notas médicas
                </li>
                <li
                  className={OpcionSelectExp === 3 ? "liSelected" : ""}
                  onClick={() => {
                    setOpcionSelectExp(3);
                  }}
                >
                  Recetas
                </li>
                <li
                  className={OpcionSelectExp === 4 ? "liSelected" : ""}
                  onClick={() => {
                    setOpcionSelectExp(4);
                  }}
                >
                  Estudios
                </li>
              </ul>
            </div>
            {OpcionSelectExp === 2 && (<>
              <div style={{ padding: "1% 3%", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                <Input
                  prefix={<SearchIcon style={{ color: "#d7d7d7", width: "18px" }} />}
                  style={{ width: "50%" }}
                  placeholder="Buscar..."
                  type="text"
                  onChange={filterList}
                />
                <Button className="GeneralBoton " onClick={OpenModalAddNota} style={{ width: "100px", color: "white" }}>
                  <Row style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    Nueva <AddCircleIcon style={{ fontSize: "15px" }} />
                  </Row>
                </Button>
              </div>
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                  padding: ".5px 5%",
                  color: "gray"
                }}>
                <p>Motivo de consuta</p> <p>Fecha y hora</p>
              </Row>
            </>
            )}
            {OpcionSelectExp === 3 && (
              <>
                <div style={{ margin: "1% 3%", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                  <Input
                    prefix={<SearchIcon style={{ color: "#d7d7d7", width: "18px" }} />}
                    style={{ width: "50%" }}
                    placeholder="Buscar..."
                    type="text"
                    onChange={filterList}
                  />
                  <Button className="GeneralBoton " onClick={OpenModalNuevaReceta} style={{ width: "100px", color: "white" }}>
                    <Row style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      Nueva <AddCircleIcon style={{ fontSize: "15px" }} />
                    </Row>
                  </Button>
                </div>
                <Row
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: "500",
                    padding: ".5px 5%",
                    color: "gray"
                  }}>
                  <p>Folio</p> <p>Fecha y hora</p>
                </Row>
              </>
            )}
            {OpcionSelectExp === 4 && (<>
              <div style={{ margin: "1% 3%", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                <Input
                  prefix={<SearchIcon style={{ color: "#d7d7d7", width: "18px" }} />}
                  style={{ width: "50%" }}
                  placeholder="Buscar..."
                  type="text"
                  onChange={filterList}
                />
                <Button className="GeneralBoton " onClick={OpenModalAddEstudi} style={{ width: "100px", color: "white" }}>
                  <Row style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    Nueva <AddCircleIcon style={{ fontSize: "15px" }} />
                  </Row>
                </Button>
              </div>
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                  padding: ".5px 5%",
                  color: "gray"
                }}>
                <p>Descripción</p> <p>Fecha y hora</p> </Row>
            </>
            )}
          </>
        } />

      <Content
        className="site-layout"
        style={{
          padding: '0 3%',
          width: "100%",
          overflow: 'initial',
        }}
      >
        {loader ? <Loader /> : (
          <>
            {OpcionSelectExp === 0 && <div></div>}
            {OpcionSelectExp === 1 && (
              // <div className="hithe">
              <Acordiones
                setEdad={setEdad}
                tipoSangreSet={setTipoSangre}
                Historial={Historial}
                getHistoriaClinica={getHistoriaClinica}
                mensajesError={
                  {
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage,
                  }
                }
                setLoader={setLoader}
                DatosContacto={DatosContacto}
              />
              // </div>
            )}
            {OpcionSelectExp === 2 && (
              <ExpedienteNotasMedicas
                DatosContacto={DatosContacto}
                NotasEvolucion={NotasEvolucion}
                AccionesModal={
                  {
                    isOpenModalAddNota: isOpenModalAddNota,
                    OpenModalAddNota: OpenModalAddNota,
                    CloseModalAddNota: CloseModalAddNota
                  }
                }
                AccionesMensaje={
                  {
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage
                  }
                }
              />
            )}
            {OpcionSelectExp === 3 && (
              // <div className="hithe">
              <RecetasExpedientes
                DatosContacto={DatosContacto}
                Recetas={ListaRecetas}
                getHistoria={getHistoriaClinica}
                AccionesModal={
                  {
                    isOpenModalNuevaReceta: isOpenModalNuevaReceta,
                    OpenModalNuevaReceta: OpenModalNuevaReceta,
                    CloseModalNuevaReceta: CloseModalNuevaReceta
                  }
                }
                AccionesMensaje={
                  {
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage
                  }
                }
              />
              // </div>
            )}
            {OpcionSelectExp === 4 && (
              // <div className="hithe">
              <EstudiosExpediente
                DatosContacto={DatosContacto}
                Estudios={ListaEstudios}
                getHistoria={getHistoriaClinica}
                AccionesModal={
                  {
                    isOpenModalAddEstudi: isOpenModalAddEstudi,
                    OpenModalAddEstudi: OpenModalAddEstudi,
                    CloseModalAddEstudi: CloseModalAddEstudi,
                    isOpenModalDetalleEstudi: isOpenModalDetalleEstudi,
                    OpenModalDetalleEstudi: OpenModalDetalleEstudi,
                    CloseModalDetalleEstudi: CloseModalDetalleEstudi,
                  }
                }
                AccionesMensaje={
                  {
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage
                  }
                }
              />
              // </div>
            )}
          </>
        )}
      </Content>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack >
  );
}
