import React, { useEffect, useState } from 'react'
import "./styles/Licencia.scss"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import InputAntd from '../componentes/GeneralComponents/InputAntd';
import SelectAntd from '../componentes/GeneralComponents/SelectAntd';
import { Boton, LeyendaError } from '../componentes/GeneralComponents/Formularios2Grids';
import { Input, Row } from 'antd';
import Logowhite from "../Img/splash.png";
import visa from "../Img/visa-svg.svg";
import mastercard from "../Img/mastercard-svg.svg";
import { Label } from '../componentes/GeneralComponents/Formularios';
import { Loader } from '../componentes/GeneralComponents/Loader';
import { Stack } from '@mui/material';
import {
   getTarjetas_request,
   addTarjeta_request,
   deleteTarjeta_request,
   getClabeInterbancaria_request,
   addClabeInterbancaria_request,
   defaultCard_request,
} from "../services/metodosPago_services";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
   subscriptionDetail_request,
   buySubscription_request,
   cancelSubscription_request,
   nextSubscriptionPay_request,
} from "../services/suscripcion_services";
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../redux/snackbarSlice';
import NavbarNuevo from '../componentes/GeneralComponents/Navbar_Nuevo';
import TarjetasCard from '../componentes/Metodos-Pagos/tarjetasCard';
import { infoUser_request } from '../services/perfil_services';
import { infoUser } from '../redux/userSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Licencia({ acciones }) {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const userInfo = useSelector((state) => state.userInfo);

   const expresiones = {
      Numeros: /^[0-9]{1,16}$/, // numeros.
      tarjeta: /^\d{1,16}$/,
      // expiracion: /^\d{1,2}\/\d{1,2}$/,
      expiracion: /^(0[1-9]|1[0-2])\/\d{2}$/,
      cvv: /^\d{1,3}$/,
   };

   const [TarjetasList, setTarjetasList] = useState([]);

   const [IdTarjeta, setIdTarjeta] = useState("");

   const [NumeroTarjeta, setNumeroTarjeta] = useState("");
   const [ExpiracionMes, setExpiracionMes] = useState(null);
   const [ExpiracionYear, setExpiracionYear] = useState("");
   const [CVV, setCVV] = useState("");

   const [loader, setLoader] = useState(false);
   const [TextoLoader, setTextoLoader] = useState("");

   const [MensajeNumeroTarjeta, setMensajeNumeroTarjeta] = useState("true");
   const [MensajeExpiracion, setMensajeExpiracion] = useState("true");
   const [MensajeCVV, setMensajeCVV] = useState("true");


   async function saveTarjeta(e) {
      e.preventDefault();
      // console.log(acciones);

      // return;
      setTextoLoader("Guardando tarjeta");
      setLoader(true);
      if (
         !!NumeroTarjeta &&
         MensajeNumeroTarjeta !== "false" &&
         !!ExpiracionMes &&
         !!ExpiracionYear &&
         MensajeExpiracion !== "false" &&
         !!CVV &&
         MensajeCVV !== "false" &&
         NumeroTarjeta.length === 16
      ) {
         let data = new FormData();

         data.append("Number", NumeroTarjeta);
         data.append("Fecha", `${ExpiracionMes}/${ExpiracionYear}`);
         data.append("Cvc", CVV);

         let response = await addTarjeta_request(data);
         if (response.ok) {
            // console.log(response.data);
            PaySubscription(response.data);
         } else {
            setLoader(false);
            dispatch(showSnackbar({
               show: true,
               text: response.mensaje,
               type: "error",
            }));
         }
      } else {
         setLoader(false);
         if (!!!NumeroTarjeta || NumeroTarjeta.length < 16) {
            setMensajeNumeroTarjeta("false");
         }
         if (!!!ExpiracionMes || !!!ExpiracionYear) {
            setMensajeExpiracion("false");
         }
         if (!!!CVV) {
            setMensajeCVV("false");
         }
         dispatch(showSnackbar({
            show: true,
            text: "Faltan campos por completar",
            type: "warning",
         }));
      }
      // setLoader(false);
      return;
   }

   async function PaySubscription(idTarjeta) {
      setTextoLoader("Realizando cargo");

      let data = new FormData();
      data.append("tarjeta", idTarjeta);

      let response = await buySubscription_request(data);
      // console.log(response);
      if (response.ok) {
         let responseUser = await infoUser_request();
         if (responseUser.ok) {
            dispatch(infoUser(responseUser.data.Usuario));
            window.location.reload();
         }
         dispatch(showSnackbar({
            show: true,
            text: "Pago completado",
            type: "success",
         }));
      } else {
         dispatch(showSnackbar({
            show: true,
            text: response.mensaje,
            type: "error",
         }));
      }
      setLoader(false);
      return;
   }

   async function getTarjetas() {
      setLoader(true);
      let response = await getTarjetas_request();
      if (response.ok) {
         // console.log(response.data);
         setTarjetasList(response.data);
      } else {
         setTarjetasList([]);
         // dispatch(showSnackbar({
         //    show: true,
         //    text: response.mensaje,
         //    type: "error",
         // }));
      }
      setLoader(false);
      return;
   }

   async function deleteTarjeta() {
      setLoader(true);
      let response = await deleteTarjeta_request(IdTarjeta);
      if (response.ok) {
         dispatch(showSnackbar({
            show: true,
            text: "Tarjeta eliminada",
            type: "success",
         }));
         getTarjetas();
      } else {
         dispatch(showSnackbar({
            show: true,
            text: response.mensaje,
            type: "error",
         }));
      }
      setLoader(false);
      return;
   }

   useEffect(() => {
      getTarjetas();
   }, [])


   return (
      <Stack>
         <NavbarNuevo />
         {loader && <Loader texto={TextoLoader} marginTop={"45vh"} />}
         <div>
            <div className='GridLicence'>
               <div className='GridLicDivBlue'>
                  {/* <div>
                     <h2>Freemium</h2>
                     <h4>$0.00 MXN</h4>
                     <p className='SubTitleLic'>Prueba YGIA app gratis por 2 meses</p>
                     <div className='TextosLic'>
                        <p><CheckCircleOutlineIcon />Acceso ilimitado a funcionalidades básicas</p>
                        <p><CheckCircleOutlineIcon />Consultorio ilimitado</p>
                        <p><CheckCircleOutlineIcon />Posicionamiento medio</p>
                        <p><CheckCircleOutlineIcon />asesoría 24/7</p>
                        <p><CheckCircleOutlineIcon />Expediente electrónico médico</p>
                        <p><CheckCircleOutlineIcon />Administración de agenda</p>

                     </div>
                     <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className='BottonGrid' onClick={accion}>Suscribirme</div></div>
                  </div> */}
                  <div>
                     <img src={Logowhite} alt="" style={{ width: "20%", height: "30%" }} />
                     <h2>Plan Ygia Superior</h2>
                     <h4>$399.00 MXN Mensuales</h4>
                     <p className='SubTitleLic'>Versión Profesional de Ygia medical</p>
                     <div className='TextosLic'>
                        <p><CheckCircleOutlineIcon />Acceso ilimitado</p>
                        <p><CheckCircleOutlineIcon />Consultorio ilimitado</p>
                        {/* <p><CheckCircleOutlineIcon />Posicionamiento medio</p> */}
                        <p><CheckCircleOutlineIcon />Asesoria 24/7</p>
                        <p><CheckCircleOutlineIcon />Expediente médico electrónico</p>
                        <p><CheckCircleOutlineIcon />Administración de agenda</p>

                     </div>
                  </div>
               </div>
               <div className='GridLicDiv'>
                  {/* <div>
                     <h2>Plan Ygia Superior</h2>
                     <h4>$399.00 MXN Mensuales</h4>
                     <p className='SubTitleLic'>Versión Profesional de Ygia medical</p>
                     <div className='TextosLic'>
                        <p><CheckCircleOutlineIcon />Acceso ilimitado a funcionalidades básicas</p>
                        <p><CheckCircleOutlineIcon />Consultorio ilimitado</p>
                        <p><CheckCircleOutlineIcon />Posicionamiento medio</p>
                        <p><CheckCircleOutlineIcon />Asesoria 24/7</p>
                        <p><CheckCircleOutlineIcon />Expediente electrónico médico</p>
                        <p><CheckCircleOutlineIcon />Administración de agenda</p>

                     </div>
                     <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className='BottonGridDisabled' onClick={() => { }}>Próximamente</div>
                     </div>
                  </div> */}
                  {userInfo.Tipo === 1 ? (
                     <div>
                        {TarjetasList.length > 0 && <h4>Tarjetas agregadas</h4>}
                        <div style={{ padding: "10px 0 10px 0" }}>
                           <TarjetasCard
                              tarjetas={TarjetasList}
                              setIdTarjeta={setIdTarjeta}
                              deleteTarjeta={deleteTarjeta}
                           />
                        </div>
                        <h4>Metodo de pago</h4>
                        <div className='FormTarjetaLic'>
                           <Row style={{ justifyContent: "space-evenly" }}>
                              <img src={visa} alt="" style={{ width: "15%", height: "10%" }} />
                              <img src={mastercard} alt="" style={{ width: "15%", height: "10%" }} />
                           </Row>
                           <form action="" style={{ padding: "0" }} onSubmit={saveTarjeta}>
                              <div style={{ padding: "3% 2%" }}>
                                 <div style={{ textAlign: "left" }}>
                                    <InputAntd
                                       className="inputblue"
                                       value={NumeroTarjeta}
                                       setStateValue={setNumeroTarjeta}
                                       label="Número de tarjeta:"
                                       id="tarjeta"
                                       style={{ margin: "2% 0 0 0", width: "100%" }}
                                       placeholder="**** **** **** ****"
                                       setStateError={setMensajeNumeroTarjeta}
                                       textError={[
                                          "Debe escribir un número de tarjeta",
                                          "Solo puede contener numeros",
                                          "Debe contener 16 dígitos",
                                       ]}
                                       type="text"
                                       valueError={MensajeNumeroTarjeta}
                                       vacio={false}
                                       disabled={false}
                                       expresionRegular={expresiones.tarjeta}
                                       maxLength={16}
                                    />
                                 </div>
                                 <Label valido={MensajeExpiracion} style={{ textAlign: "left", marginTop: "4%" }}>Fecha de expiración:</Label>
                                 <div
                                    style={{
                                       display: "flex",
                                       gap: "1.5%",
                                       alignItems: "center",
                                       padding: "2% 0 0 0"
                                    }}
                                 >
                                    <SelectAntd
                                       // label="Género:"
                                       value={ExpiracionMes}
                                       style={{ width: "100%" }}
                                       placeholder="MM"
                                       options={[
                                          { value: "01", label: "01" },
                                          { value: "02", label: "02" },
                                          { value: "03", label: "03" },
                                          { value: "04", label: "04" },
                                          { value: "05", label: "05" },
                                          { value: "06", label: "06" },
                                          { value: "07", label: "07" },
                                          { value: "08", label: "08" },
                                          { value: "09", label: "09" },
                                          { value: "10", label: "10" },
                                          { value: "11", label: "11" },
                                          { value: "12", label: "12" },
                                       ]}
                                       setStateValue={setExpiracionMes}
                                       setStateError={setMensajeExpiracion}
                                       valueError={MensajeExpiracion}
                                    // textError={["Debe completar fecha de expiración"]}
                                    />
                                    <p style={{ marginBottom: "2%" }}>/</p>
                                    <Input
                                       value={ExpiracionYear}
                                       style={{ marginBottom: "1%", width: "20%" }}
                                       type="text"
                                       placeholder="AA"
                                       maxLength={2}
                                       onChange={(e) => {
                                          setExpiracionYear(e.target.value);
                                          if (e.target.value.length > 0 && !!ExpiracionMes) {
                                             setMensajeExpiracion("true");
                                          } else {
                                             setMensajeExpiracion("false");
                                          }
                                       }}
                                       status={MensajeExpiracion === "false" && "error"}
                                    />
                                 </div>
                                 <LeyendaError
                                    valido={MensajeExpiracion}
                                    style={{
                                       display: MensajeExpiracion === "false" ? "flex" : "none",
                                    }}
                                 >
                                    Debe completar fecha de expiración
                                 </LeyendaError>
                                 <Label valido={MensajeCVV} style={{ textAlign: "left", marginTop: "4%" }}>CVV:</Label>
                                 <div style={{ textAlign: "left", padding: "2% 0 0 0" }}>
                                    <Input
                                       value={CVV}
                                       style={{ width: "20%" }}
                                       type="text"
                                       placeholder="***"
                                       maxLength={4}
                                       onChange={(e) => {
                                          setCVV(e.target.value);
                                          if (e.target.value.length > 0) {
                                             setMensajeCVV("true");
                                          } else {
                                             setMensajeCVV("false");
                                          }
                                       }}
                                       status={MensajeCVV === "false" && "error"}
                                    />
                                 </div>
                                 <LeyendaError
                                    valido={MensajeCVV}
                                    style={{
                                       display: MensajeCVV === "false" ? "flex" : "none",
                                    }}
                                 >
                                    Debe escribir código CVV
                                 </LeyendaError>
                                 <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "3%" }}>
                                    <Boton
                                       type="submit"
                                       style={{
                                          width: "100%",
                                          height: "30px",
                                          backgroundColor: "#148f9f",
                                          color: "white",
                                          borderRadius: ".2rem",
                                          cursor: "pointer",

                                       }}
                                    >
                                       Contratar
                                    </Boton>
                                 </Row>
                              </div>
                           </form>
                        </div>
                     </div>
                  ) : (
                     <div>
                        {(userInfo.Paquete === "0" && userInfo.PaqueteStatus === 1) && (
                           <>
                              <h2>¡Lo sentimos!</h2>
                              <h4>Comunique a su médico que debe contratar nuestro plan para acceder a los servicios</h4>
                           </>
                        )}
                        {(userInfo.Paquete !== "0" && userInfo.PaqueteStatus === 2) && (
                           <>
                              <h2>¡Lo sentimos!</h2>
                              <h4>La renovación de servicio tuvo complicaciones, por favor comunique a su medico que revise su apartado de "Suscripción"</h4>
                           </>
                        )}
                        {(userInfo.Paquete !== "0" && userInfo.PaqueteStatus === 3) && (
                           <>
                              <h2>¡Lo sentimos!</h2>
                              <h4>La suscripción de su médico fue cancelada, por favor comunique a su medico revisar sus metodos de pago</h4>
                           </>
                        )}

                     </div>
                  )}

               </div>
            </div>
         </div>
      </Stack>
   )
}

export default Licencia;
