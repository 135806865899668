import React, { useEffect, useState } from "react";
import "../styles/AcordionesHistorialClinico.scss"
import { useNavigate, useLocation } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Input, Select, DatePicker, Checkbox, Button, Row } from "antd";
import TextAreaAntd from "../../../componentes/GeneralComponents/TextAreaAntd";
import InputAntd from "../../../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../../../componentes/GeneralComponents/SelectAntd";

import { getHistoriasClinicas_request } from "../../../services/expediente_service";
import {
  getEstado_request,
  getLocalidad_request,
  addExpediente_request,
} from "../../../services/expediente_service";

import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

// Librerias mui acordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckGuardar from "../../../Img/Iconos/checkmark_white.svg";

import { Label } from "../../GeneralComponents/Formularios";
import { Loader } from "../../GeneralComponents/Loader";
import { Edit } from "@mui/icons-material";

import Stack from "@mui/material/Stack";
import DatePickerAntd from "../../GeneralComponents/DatePickerAntd";

function HistorialClinico({ setEdad, tipoSangreSet, Historial, getHistoriaClinica, mensajesError, setLoader, DatosContacto }) {
  const location = useLocation();

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  const [DisabledInputs, setDisabledInputs] = useState(true);

  // VARIABLES HISTORIA CLINICA
  const [TipoSangre, setTipoSangre] = useState("");
  const [Alergias, setAlergias] = useState("");
  const [Refiere, setRefiere] = useState("");
  const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
  const [EstadoCivil, setEstadoCivil] = useState(null);
  const [Ocupacion, setOcupacion] = useState("");
  const [Sexo, setSexo] = useState(null);
  const [Nacimiento, setNacimiento] = useState("");
  const [Nacionalidad, setNacionalidad] = useState("");
  const [Estado, setEstado] = useState(null);
  const [Localidad, setLocalidad] = useState(null);
  const [CodigoPostal, setCodigoPostal] = useState("");
  const [NombrePareja, setNombrePareja] = useState("");
  const [EdadPareja, setEdadPareja] = useState("");
  const [OcupacionPareja, setOcupacionPareja] = useState("");
  const [NotasPareja, setNotasPareja] = useState("");
  const [AHeredoFamiliares, setAHeredoFamiliares] = useState("");
  const [APersonalesPatologicos, setAPersonalesPatologicos] = useState("");
  const [APersonalesNoPatologicos, setAPersonalesNoPatologicos] = useState("");
  const [AAndroGineGeneral, setAAndroGineGeneral] = useState("");
  const [AAndroGine, setAAndroGine] = useState("");
  const [Embarazada, setEmbarazada] = useState(0);
  const [SignosSintomas, setSignosSintomas] = useState("");
  const [AparatoCardiovascular, setAparatoCardiovascular] = useState("");
  const [AparatoRespiratorio, setAparatoRespiratorio] = useState("");
  const [AparatoDigestivo, setAparatoDigestivo] = useState("");
  const [SistemaNefrourologico, setSistemaNefrourologico] = useState("");
  const [SistemaEndocrino, setSistemaEndocrino] = useState("");
  const [SistemaHematoproyetico, setSistemaHematoproyetico] = useState("");
  const [SistemaNervioso, setSistemaNervioso] = useState("");
  const [MusculoEsqueletico, setMusculoEsqueletico] = useState("");
  const [PielTegumentos, setPielTegumentos] = useState("");
  const [OrganosSentidos, setOrganosSentidos] = useState("");
  const [EsferaPsiquica, setEsferaPsiquica] = useState("");
  const [Genitales, setGenitales] = useState("");

  // #### INICIO DE VARIABLES CONSULTA/NOTA EVOLUCION ####

  const [EstadosList, setEstadosList] = useState([]);
  const [LocalidadesList, setLocalidadesList] = useState([]);

  const [MensajeSexo, setMensajeSexo] = useState("true");
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");

  async function setHistoriaClinica() {
    // setSexo(data.);

    // let response = await getHistoriaClinica();
    // console.log(Historial);
    // let Historial = response.data;
    if (document.domain === "localhost") {
      console.log(Historial.historial);
    }
    if (Historial.historial !== null) {
      setTipoSangre(Historial.historial.tipoSangre);
      tipoSangreSet(Historial.historial.tipoSangre);
      setAlergias(Historial.historial.alergias);
      setRefiere(Historial.historial.refiere);
      setObservacionesPaciente(Historial.historial.observaciones);
      setAHeredoFamiliares(Historial.historial.aHereditarios);
      setAPersonalesPatologicos(Historial.historial.aPatologicos);
      setAPersonalesNoPatologicos(Historial.historial.aNoPatologicos);
      setAAndroGineGeneral(Historial.historial.aBiologicoGeneral);
      setAAndroGine(Historial.historial.aBiologicoEspecifico);
      setSignosSintomas(Historial.historial.sGenerales);
      setAparatoCardiovascular(Historial.historial.aCardiovascular);
      setAparatoRespiratorio(Historial.historial.aRespiratorio);
      setAparatoDigestivo(Historial.historial.aDigestivo);
      setSistemaNefrourologico(Historial.historial.sNefrourologico);
      setSistemaEndocrino(Historial.historial.sEndocrino);
      setSistemaHematoproyetico(Historial.historial.sHematopoyetico);
      setSistemaNervioso(Historial.historial.sNervioso);
      setMusculoEsqueletico(Historial.historial.sMusculo);
      setPielTegumentos(Historial.historial.piel);
      setOrganosSentidos(Historial.historial.oSentidos);
      setEsferaPsiquica(Historial.historial.ePsiquica);
      setGenitales(Historial.historial.sGenitales);
      setEstadoCivil(Historial.historial.estadoCivil !== null ? Historial.historial.estadoCivil.toString() : null);
      setOcupacion(Historial.historial.ocupacion);
      setNombrePareja(Historial.historial.nombrePareja);
      setEdadPareja(Historial.historial.edadPareja);
      setOcupacionPareja(Historial.historial.ocupacionPareja);
      setNotasPareja(Historial.historial.nota);
    }

    if (Historial.ficha !== null) {
      setSexo(Historial.ficha.sexo);

      if (!!Historial.ficha.fechaNacimiento) {
        let arrayFechaTemp = Historial.ficha.fechaNacimiento.split("-");
        let fechaTemp = `${arrayFechaTemp[2]}-${arrayFechaTemp[1]}-${arrayFechaTemp[0]}`;
        getEdad(fechaTemp);
        setNacimiento(fechaTemp);
      }
      setNacionalidad(Historial.ficha.nacionalidad);
      if (!!Historial.ficha.estado) {
        setEstado(Historial.ficha.estado);
        getLocalidades(Historial.ficha.estado);
        setLocalidad(Historial.ficha.localidad);
      }
      setCodigoPostal(Historial.ficha.cp);
    }


    // console.log(data.estudios);
  }

  async function getEstados() {
    let response = await getEstado_request();
    if (response.ok) {
      // console.log(response.data);
      let estados_temp = [];
      response.data.forEach((estado) => {
        estados_temp.push({
          value: estado.id.toString(),
          label: estado.Estado,
        });
      });
      setEstadosList(estados_temp);
    } else {
      setEstadosList([]);
    }
  }

  async function getLocalidades(estado) {
    let response = await getLocalidad_request(estado);
    if (response.ok) {
      // console.log(response.data);
      let localidades_temp = [];
      response.data.forEach((estado) => {
        localidades_temp.push({ value: estado, label: estado });
      });
      setLocalidadesList(localidades_temp);
    } else {
      setLocalidadesList([]);
    }
  }

  async function saveHClinico() {
    setLoader(true);
    if (!!Sexo && !!Nacimiento) {
      let data = {
        aBiologicoGeneral: AAndroGineGeneral,
        aBiologicoEspecifico: AAndroGine,
        aCardiovascular: AparatoCardiovascular,
        aDigestivo: AparatoDigestivo,
        aHereditarios: AHeredoFamiliares,
        aNoPatologicos: APersonalesNoPatologicos,
        aPatologicos: APersonalesPatologicos,
        aRespiratorio: AparatoRespiratorio,
        ePsiquica: EsferaPsiquica,
        oSentidos: OrganosSentidos,
        piel: PielTegumentos,
        sEndocrino: SistemaEndocrino,
        sGenerales: SignosSintomas,
        sGenitales: Genitales,
        sHematopoyetico: SistemaHematoproyetico,
        sMusculo: MusculoEsqueletico,
        sNefrourologico: SistemaNefrourologico,
        sNervioso: SistemaNervioso,
        alergias: Alergias,
        observaciones: ObservacionesPaciente,
        refiere: Refiere,
        sexo: Sexo,
        nacimiento: Nacimiento,
        nacionalidad: Nacionalidad,
        estado: Estado,
        localidad: Localidad,
        cp: CodigoPostal,
        tipoSangre: TipoSangre,
        ocupacion: Ocupacion,
        estadoCivil: EstadoCivil,
        nombrePareja: NombrePareja,
        edadPareja: EdadPareja,
        ocupacionPareja: OcupacionPareja,
        nota: NotasPareja,
      };
      // console.log(JSON.stringify(data));
      // return;
      let response = await addExpediente_request(data, DatosContacto.id);
      if (response.ok) {
        mensajesError.setOpen(true);
        mensajesError.setTypeMessage("success");
        mensajesError.setMessage("Cambios guardados");
        setDisabledInputs(true);
        getHistoriaClinica();
      } else {
        mensajesError.setOpen(true);
        mensajesError.setTypeMessage("error");
        mensajesError.setMessage(response.mensaje);
      }
    } else {
      if (!!!Sexo) {
        setMensajeSexo("false");
      }
      if (!!!Nacimiento) {
        setMensajeNacimiento("false");
      }

      mensajesError.setOpen(true);
      mensajesError.setTypeMessage("warning");
      mensajesError.setMessage("Faltan campos por completar");

    }
    setLoader(false);
    return;
  }

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString.replace(/-/g, "/"));
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    // console.log(edad);
    setEdad(edad);
    // return edad;
  }

  function filterOptions(input, option) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
  }

  function filterSort(optionA, optionB) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
  }

  useEffect(() => {
    getEstados();
    setHistoriaClinica();
  }, []);

  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "end",
          marginBottom: "1%",

        }}
      >
        {DisabledInputs ? (
          <Button
            onClick={() => {
              setDisabledInputs(false);
            }}
            style={{
              backgroundColor: "#148f9f",
              color: "white",
              width: "100px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Editar</div>
            <Edit
              sx={{ width: "20%" }}
              fontSize="small"
            />
          </Button>
        ) : (
          // <div style={{ width: "5%" }} onClick={() => { setDisabledInputs(false) }}>
          //   <div>
          //     <img
          //       style={{ width: "16px", cursor: "pointer" }}
          //       src={Editar}
          //       alt=""
          //     />
          //   </div>
          // </div>
          <Button
            onClick={() => {
              saveHClinico();
            }}
            style={{
              backgroundColor: "#148f9f",
              color: "white",
              width: "100px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >


            <div>Guardar</div>
            <img
              style={{ width: "12px", height: "12px" }}
              src={CheckGuardar}
              alt=""
            />

          </Button>
          // <div style={{ width: "5%", cursor: "pointer" }} onClick={() => { setDisabledInputs(true) }}>
          //   <div>
          //     <h3 style={{ color: "#148f9f" }}>x</h3>
          //   </div>
          // </div>
        )}
      </div>
      {/* INFORMACION GENERAL */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Información general</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%", padding: "1%", background: "#fff" }}>
            <InputAntd
              label="Tipo de sangre:"
              className="inputblue"
              value={TipoSangre}
              setStateValue={setTipoSangre}
              placeholder="Tipo de sangre"
              vacio={true}
              readOnly={DisabledInputs}
              styleDiv={{ width: "130px" }}
            />
            <TextAreaAntd
              label="Alergias:"
              value={Alergias}
              placeholder="Alergias"
              style={{
                // margin: ".5% 0",
                marginBottom: ".5%",
                fontFamily: "sans-serif",
                width: "100%",
              }}
              columns="30"
              autoSize={true}
              setStateValue={setAlergias}
              readOnly={DisabledInputs}
            />


            <TextAreaAntd
              styleDiv={{ width: "100%" }}
              label="Quien Refiere:"
              value={Refiere}
              placeholder="Quien Refiere"
              style={{
                // margin: ".5% 0",
                marginBottom: ".5%",
                fontFamily: "sans-serif",
              }}
              columns="30"
              autoSize={true}
              setStateValue={setRefiere}
              readOnly={DisabledInputs}
            />
            <TextAreaAntd
              styleDiv={{ width: "100%" }}
              label="Observaciones sobre el paciente:"
              value={ObservacionesPaciente}
              placeholder="Observaciones sobre el paciente"
              style={{
                // margin: ".5% 0",
                marginBottom: ".5%",
                fontFamily: "sans-serif",
              }}
              columns="30"
              autoSize={true}
              setStateValue={setObservacionesPaciente}
              readOnly={DisabledInputs}
            />

            <div className="BotonesHistorial">
              <SelectAntd
                label="Estado civil:"
                value={EstadoCivil}
                style={{ width: "100%" }}
                placeholder="Seleccione un estado civil"
                options={
                  [
                    { value: "0", label: "Soltero/a" },
                    { value: "1", label: "Casado/a" },
                    { value: "2", label: "Viudo/a" },
                    { value: "3", label: "Divorciado/a" },
                    { value: "4", label: "Separado/a" },
                    { value: "5", label: "Concubinato" },
                  ]
                }
                setStateValue={setEstadoCivil}
                styleDiv={{ width: "100%" }}
                disabled={DisabledInputs}
              // setStateError={setMensajeSexo}
              // valueError={MensajeSexo}
              // textError={"Debe seleccionar un estado civil"}
              />
              <InputAntd
                className="inputblue"
                value={Ocupacion}
                setStateValue={setOcupacion}
                label="Ocupación:"
                placeholder="Escribe una ocupación"
                // valueError={MensajeAddNombrecontacto}
                vacio={true}
                // disabled={false}
                // expresionRegular={expresiones.Letras}
                styleDiv={{ width: "100%" }}
                readOnly={DisabledInputs}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "2%",
                justifyContent: "space-between",
              }}
            >
              <SelectAntd
                label="Género:"
                value={Sexo}
                style={{ width: "100%" }}
                styleDiv={{ width: "100%" }}
                placeholder="Género"
                options={[
                  { value: "Femenino", label: "Femenino" },
                  { value: "Masculino", label: "Masculino" },
                ]}
                setStateValue={setSexo}
                disabled={DisabledInputs}
                setStateError={setMensajeSexo}
                valueError={MensajeSexo}
                textError={["Debe elegir un género"]}
                vacio={false}
              />
              <DatePickerAntd
                label="Nacimiento:"
                placeholder="DD/MM/AAAA"
                style={{ width: "100%", background: "white" }}
                value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                setStateValue={setNacimiento}
                disabled={DisabledInputs}
                styleDiv={{ width: "100%" }}
                // setEdad={setEdad}
                setStateError={setMensajeNacimiento}
                valueError={MensajeNacimiento}
                textError={["Debe elegir una fecha de nacimiento"]}
                vacio={false}
                setEdad={setEdad}
              />
            </div>

            <div
              className="BotonesHistorial"
            >
              <InputAntd
                className="inputblue"
                value={Nacionalidad}
                setStateValue={setNacionalidad}
                label="Nacionalidad:"
                placeholder="Nacionalidad"
                vacio={true}
                styleDiv={{ width: "100%" }}
                disabled={DisabledInputs}
              />
              <div style={{ width: "100%" }}>
                <Label>Estado:</Label>
                <Select
                  disabled={DisabledInputs}
                  showSearch
                  style={{
                    width: "100%",
                    margin: ".5% 0",
                  }}
                  placeholder="Estado"
                  optionFilterProp="children"
                  filterOption={filterOptions}
                  filterSort={filterSort}
                  value={Estado}
                  onSelect={(e) => {
                    setEstado(e);
                    getLocalidades(e);
                  }}
                  options={EstadosList}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Label>Localidad:</Label>
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    margin: ".5% 0",
                  }}
                  placeholder="Localidad"
                  optionFilterProp="children"
                  filterOption={filterOptions}
                  filterSort={filterSort}
                  value={Localidad}
                  onSelect={(e) => {
                    setLocalidad(e);
                  }}
                  options={LocalidadesList}
                  disabled={
                    LocalidadesList.length === 0
                      ? true
                      : DisabledInputs
                        ? true
                        : false
                  }
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* DATOS DE PAREJA */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Datos de pareja
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InputAntd
            label="Nombre:"
            className="inputblue"
            value={NombrePareja}
            setStateValue={setNombrePareja}
            placeholder="Escriba un nombre completo"
            vacio={true}
            readOnly={DisabledInputs}
            styleDiv={{ width: "100%" }}
          // maxLength={15}
          />
          <Row style={{ justifyContent: "space-between" }}>
            <InputAntd
              className="inputblue"
              value={OcupacionPareja}
              setStateValue={setOcupacionPareja}
              label="Ocupación:"
              placeholder="Escribe una ocupación"
              // valueError={MensajeAddNombrecontacto}
              vacio={true}
              // disabled={false}
              // expresionRegular={expresiones.Letras}
              readOnly={DisabledInputs}
              styleDiv={{ width: "70%" }}
            />

            <InputAntd
              label="Edad:"
              className="inputblue"
              value={EdadPareja}
              setStateValue={setEdadPareja}
              placeholder="Edad"
              vacio={true}
              readOnly={DisabledInputs}
              styleDiv={{ width: "15%" }}
              maxLength={2}
            />
          </Row>
          <TextAreaAntd
            label="Notas/Comentarios:"
            value={NotasPareja}
            placeholder="Escriba una nota o comentario"
            style={{
              // margin: ".5% 0",
              marginBottom: ".5%",
              fontFamily: "sans-serif",
            }}
            columns="30"
            autoSize={true}
            setStateValue={setNotasPareja}
            readOnly={DisabledInputs}
          />
        </AccordionDetails>
      </Accordion>
      {/* ANTECEDENTES HEREDO FAMILIARES */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Antecedentes heredo-familiares</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextArea
            // readOnly
            placeholder="Antecedentes heredo-familiares"
            style={{
              margin: ".5% 0",
              fontFamily: "sans-serif",
              border: "0",
            }}
            name=""
            id=""
            cols="30"
            onChange={(e) => {
              setAHeredoFamiliares(e.target.value);
            }}
            value={AHeredoFamiliares}
            autoSize
            readOnly={DisabledInputs}
          />
        </AccordionDetails>
      </Accordion>
      {/* ANTECEDENTES PERSONALES PATOLOGICOS*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Antecedentes personales patológicos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextArea
            // readOnly
            placeholder="Antecedentes personales patológicos"
            style={{
              margin: ".5% 0",
              fontFamily: "sans-serif",
              border: "0",
            }}
            name=""
            id=""
            cols="30"
            onChange={(e) => {
              setAPersonalesPatologicos(e.target.value);
            }}
            value={APersonalesPatologicos}
            autoSize
            readOnly={DisabledInputs}
          />
        </AccordionDetails>
      </Accordion>
      {/* ANTECEDENTES PERSONALES NO PATOLOGICOS */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Antecedentes personales no patológicos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextArea
            placeholder="Antecedentes personales no patológicos"
            style={{
              margin: ".5% 0",
              fontFamily: "sans-serif",

              border: "0",
            }}
            onChange={(e) => {
              setAPersonalesNoPatologicos(e.target.value);
            }}
            value={APersonalesNoPatologicos}
            name=""
            id=""
            cols="30"
            autoSize
            readOnly={DisabledInputs}
          />
        </AccordionDetails>
      </Accordion>
      {/* ANTECEDENTES SEXUALES */}
      {!!Sexo && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            {Sexo === "Masculino" && (
              <Typography>Antecedentes andrológicos específicos</Typography>
            )}
            {Sexo === "Femenino" && (
              <Typography> Antecedentes Gineco-obstetricos</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {/* <Label>¿Paciente embarazada?</Label>
            <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
              <Checkbox checked={Embarazada === 1 ? true : false} onChange={(e) => {
                if (e.target.checked) {
                  setEmbarazada(1);
                }
              }}>Si</Checkbox>
              <Checkbox checked={Embarazada === 0 ? true : false} onChange={(e) => {
                if (e.target.checked) {
                  setEmbarazada(0);
                }
              }}>No</Checkbox>
            </div> */}
            <label htmlFor="">Generales</label>
            <TextArea
              placeholder="Inicio de vida sexual, método de planificación familiar, orientación sexual, si sostiene relaciones sexuales de alto riesgo, alteraciones de la libido, si ha padecido enfermedades de transmisión sexual."
              style={{
                margin: ".5% 0",
                fontFamily: "sans-serif",
              }}
              name=""
              id=""
              cols="30"
              autoSize
              onChange={(e) => {
                setAAndroGineGeneral(e.target.value);
              }}
              value={AAndroGineGeneral}
              readOnly={DisabledInputs}
            />
            <label htmlFor="">
              {Sexo === "Masculino"
                ? "Andrológicos específicos"
                : "Ginecoobstetricos"}
            </label>
            <TextArea
              placeholder={
                Sexo === "Masculino"
                  ? "Priapismo, alteraciones de la erección y/o eyaculación, secreción uretral, dolor testicular, alteraciones escrotales, enfermedades de transmisión sexual."
                  : "Menarca, Telarca, Pubarca, Gestas, Partos Cesáreas, Abortos, Dismenorreas, Ciclo menstrual."
              }
              style={{
                margin: ".5% 0",
                fontFamily: "sans-serif",
              }}
              name=""
              id=""
              cols="30"
              autoSize
              onChange={(e) => {
                setAAndroGine(e.target.value);
              }}
              value={AAndroGine}
              readOnly={DisabledInputs}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {/* INTERROGATORIO POR APARATOS Y SISTEMAS */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Interrogatorio por aparatos y sistemas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!!SignosSintomas || !DisabledInputs) && (
            <>
              <label htmlFor="">Signos y síntomas generales</label>
              <TextArea
                className="InputNormal"
                placeholder="Fiebre, cefalea, astenia, adinamia y anorexia."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={SignosSintomas}
                onChange={(e) => {
                  setSignosSintomas(e.target.value);
                }}
              />
            </>
          )}

          {(!!AparatoCardiovascular || !DisabledInputs) && (
            <>
              <label htmlFor="">Aparato cardiovascular</label>
              <TextArea
                className="InputNormal"
                placeholder="Disnea, dolor precordial, palpitaciones, sincope, lipotimia, edema, cianosis, acúfenos, fosfenos. "
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={AparatoCardiovascular}
                onChange={(e) => {
                  setAparatoCardiovascular(e.target.value);
                }}
              />
            </>
          )}

          {(!!AparatoRespiratorio || !DisabledInputs) && (
            <>
              <label htmlFor="">Aparato respiratorio</label>
              <TextArea
                className="InputNormal"
                placeholder="Rinorrea, rinolalia, tos, expectoración, disnea, dolor torácico, epistaxis, disfonía, hemoptisis, vómica, sibilancias audibles a distancia. "
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={AparatoRespiratorio}
                onChange={(e) => {
                  setAparatoRespiratorio(e.target.value);
                }}
              />
            </>
          )}

          {(!!AparatoDigestivo || !DisabledInputs) && (
            <>
              <label htmlFor="">Aparato digestivo</label>
              <TextArea
                className="InputNormal"
                placeholder="Trastornos de la deglución, de la digestión, de la defecación, nausea, vómito, dolor abdominal, diarrea, constipación, ictericia, rumiación, regurgitación, pirosis, aerofagia, eructos, meteorismos, distención abdominal, flatulencia, hematemesis, características de las heces fecales, diarrea, estreñimiento, acolia, hipocolia, melena, rectorragia, lientería, pujo, tenesmo y prurito anal."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={AparatoDigestivo}
                onChange={(e) => {
                  setAparatoDigestivo(e.target.value);
                }}
              />
            </>
          )}

          {(!!SistemaNefrourologico || !DisabledInputs) && (
            <>
              <label htmlFor="">Sistema nefrourologico</label>
              <TextArea
                className="InputNormal"
                placeholder="Dolor renoureteral, hematuria, piuria, coluría, oliguria, tenesmo, control de esfínteres, cólico renal, edema, trastornos en el volumen urinario, nictámero, urgencia, características del chorro urinario."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={SistemaNefrourologico}
                onChange={(e) => {
                  setSistemaNefrourologico(e.target.value);
                }}
              />
            </>
          )}

          {(!!SistemaEndocrino || !DisabledInputs) && (
            <>
              <label htmlFor="">Sistema endocrino y metabolismo</label>
              <TextArea
                className="InputNormal"
                placeholder="Intolerancia al calor o al frio, pérdida o aumento de peso, alteraciones del color de la piel, vello corporal, distribución de la grasa corporal, astenia o adinamia, alteraciones del volumen urinario, amenorreas, ginecomastia, galactorrea, alteraciones de la libido, espasmos o calambres musculares."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={SistemaEndocrino}
                onChange={(e) => {
                  setSistemaEndocrino(e.target.value);
                }}
              />
            </>
          )}

          {(!!SistemaHematoproyetico || !DisabledInputs) && (
            <>
              <label htmlFor="">Sistema hematopoyético</label>
              <TextArea
                className="InputNormal"
                placeholder="Palidez, rubicundez, adenomegalias, hemorragias, fiebre, fatigabilidad, equimosis, petequias y adenomegalias."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={SistemaHematoproyetico}
                onChange={(e) => {
                  setSistemaHematoproyetico(e.target.value);
                }}
              />
            </>
          )}

          {(!!SistemaNervioso || !DisabledInputs) && (
            <>
              <label htmlFor="">Sistema nervioso</label>
              <TextArea
                className="InputNormal"
                placeholder="Cefalalgia, pérdida de conocimiento, mareos vértigo y trastornos del equilibrio, movimientos anormales involuntarios, debilidad muscular, convulsiones, trastornos de la visión, dolor hormigueo y adormecimiento, trastornos del sueño."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={SistemaNervioso}
                onChange={(e) => {
                  setSistemaNervioso(e.target.value);
                }}
              />
            </>
          )}

          {(!!MusculoEsqueletico || !DisabledInputs) && (
            <>
              <label htmlFor="">Sistema musculo esquelético</label>
              <TextArea
                className="InputNormal"
                placeholder="Mialgias, dolor óseo, artralgias, alteraciones en la marcha, hipotonía, disminución del volumenmuscular, limitación de movimientos y deformidades."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={MusculoEsqueletico}
                onChange={(e) => {
                  setMusculoEsqueletico(e.target.value);
                }}
              />
            </>
          )}

          {(!!PielTegumentos || !DisabledInputs) && (
            <>
              <label htmlFor="">Piel y tegumentos</label>
              <TextArea
                className="InputNormal"
                placeholder="Coloración, pigmentación, prurito, características del pelo, uñas, lesiones (primarias y secundarias), hiperhidrosis y xerodermia."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={PielTegumentos}
                onChange={(e) => {
                  setPielTegumentos(e.target.value);
                }}
              />
            </>
          )}

          {(!!OrganosSentidos || !DisabledInputs) && (
            <>
              <label htmlFor="">Órganos de los sentidos</label>
              <TextArea
                className="InputNormal"
                placeholder="Alteraciones de la visión, de la audición, del olfato, del gusto y del tacto (hipo, hiper o disfunción). Mareo y sensación de líquido en el oído."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={OrganosSentidos}
                onChange={(e) => {
                  setOrganosSentidos(e.target.value);
                }}
              />
            </>
          )}

          {(!!EsferaPsiquica || !DisabledInputs) && (
            <>
              <label htmlFor="">Esfera psíquica</label>
              <TextArea
                className="InputNormal"
                placeholder="Tristeza, euforia, alteraciones del sueño, , terrores nocturnos, ideaciones, miedo exagerado a situaciones comunes, irritabilidad, apatía."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={EsferaPsiquica}
                onChange={(e) => {
                  setEsferaPsiquica(e.target.value);
                }}
              />
            </>
          )}

          {(!!Genitales || !DisabledInputs) && (
            <>
              <label htmlFor="">Genitourinario</label>
              <TextArea
                className="InputNormal"
                placeholder="Escriba..."
                style={{
                  fontFamily: "sans-serif",
                  width: "100%",
                  padding: ".5%",
                }}
                name=""
                id=""
                cols="30"
                autoSize
                readOnly={DisabledInputs}
                value={Genitales}
                onChange={(e) => {
                  setGenitales(e.target.value);
                }}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default HistorialClinico;
